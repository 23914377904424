/* Cookie */
.CookieConsent {
  background-color: $cc-grey;
  color: $white;
  padding: u(15) 0;
  transition-property: transform, opacity, visibility;
  transition-duration: .2s;
  transition-delay: 0s, .2s, .2s;

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  opacity: .9;

  p {
      margin-bottom: 0;
  }
}

.CookieConsent:not(.is-active) {
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
}

.CookieConsent > .Container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mq($until: 550px, $media-type: $mq-type, $mq_debug: null) {
    flex-direction: column;
  } 
}

.cookie-text {
  font-size: u(15);
}

.CookieConsent  a {
  font-weight: bold;
  margin-left: u(10);
  color: $white;
  text-decoration: underline;

  &,
  &:active,
  &:focus,
  &:hover {
      color: $white;
  }
}

.Close {
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  margin-left: u(10);
  line-height: 1;

  &.Button--outline {
    color: $white;
    border-radius: 50px;

    &:hover {
      color: $white;
    }
  }

  @include mq($until: 550px, $media-type: $mq-type, $mq_debug: null) {
    margin-top: 20px;
    margin-left: 0;
  } 
}

