/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-ProximaNova:                      "ProximaNova", sans-serif !default; // 300, 400, 600, 800, 900

//Proxima Nova

@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-Light.woff2') format('woff2'),
         url('../fonts/ProximaNova-Light.woff') format('woff');
    font-weight: 300;
}
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-LightItalic.woff2') format('woff2'),
         url('../fonts/ProximaNova-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}


@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
         url('../fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: 400;
}
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-RegularItalic.woff2') format('woff2'),
         url('../fonts/ProximaNova-RegularItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
         url('../fonts/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
}
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-SemiboldItalic.woff2') format('woff2'),
         url('../fonts/ProximaNova-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}


@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
         url('../fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: 700;
}
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-BoldIt.woff2') format('woff2'),
         url('../fonts/ProximaNova-BoldIt.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}


@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-Extrabold.woff2') format('woff2'),
         url('../fonts/ProximaNova-Extrabold.woff') format('woff');
    font-weight: 800;
}


@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-Black.woff2') format('woff2'),
         url('../fonts/ProximaNova-Black.woff') format('woff');
    font-style: normal;
    font-weight: 900;
}
