/* Slider: navigation */
.Slider-button {
    @include dimensions(48, 48);
    @include position(absolute, $bottom: 0, $z: 10);
    // margin: 0 u(50);
    cursor: pointer;
    // background-color: $white;
    background-position: center;
    background-repeat: no-repeat;

    &.is-disabled {
        cursor: auto;
        opacity: 0.35;
    }

    &.is-locked {
        display: none;
    }
}

.Slider-button--next:focus, .Slider-button--prev:focus {
    outline: none;
  }

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
    background-image: svg-url("<svg width='20px' height='13px' viewBox='0 0 20 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='DESIGN' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='01_home' transform='translate(-172.000000, -2046.000000)' fill='#{$black}' fill-rule='nonzero'><g id='diensten' transform='translate(158.000000, 1211.000000)'><g id='pijl-copy' transform='translate(24.000000, 842.000000) scale(-1, 1) translate(-24.000000, -842.000000) translate(0.000000, 818.000000)'><polygon id='long-arrow-right-copy-2' points='32.105 22.988 27.012 17.723 27.712 17 34 23.5 27.712 30 27.012 29.277 32.105 24.012 14 24.012 14 22.988'></polygon></g></g></g></g></svg>");
    right: auto;
    left: 0;
}

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
    background-image: svg-url("<svg width='20px' height='13px' viewBox='0 0 20 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='DESIGN' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='02_diensten' transform='translate(-922.000000, -591.000000)' fill='#{$black}' fill-rule='nonzero'><g id='werkbaar-werk' transform='translate(741.000000, 573.000000)'><polygon id='long-arrow-right-copy' points='199.13648 23.988 194.04348 18.723 194.74348 18 201.03148 24.5 194.74348 31 194.04348 30.277 199.13648 25.012 181.03148 25.012 181.03148 23.988'></polygon></g></g></g></svg>");
    right: 0;
    left: auto;
}

.Referenties {
    .Slider-button {
        @include dimensions(48, 48);
        @include position(absolute, $top: 0, $bottom: 0, $z: 10);
        margin: auto 0;
        cursor: pointer;
        background-color: none;
        background-position: center;
        background-repeat: no-repeat;
    
        &.is-disabled {
            cursor: auto;
            opacity: 0.35;
        }
    
        &.is-locked {
            display: none;
        }
    }
    
    .Slider-button--next:focus, .Slider-button--prev:focus {
        outline: none;
      }
    
    .Slider-button--prev,
    .Slider--rtl .Slider-button--next {
        background-image: svg-url("<svg width='20px' height='13px' viewBox='0 0 20 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='DESIGN' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='01_home' transform='translate(-172.000000, -2046.000000)' fill='#{$black}' fill-rule='nonzero'><g id='diensten' transform='translate(158.000000, 1211.000000)'><g id='pijl-copy' transform='translate(24.000000, 842.000000) scale(-1, 1) translate(-24.000000, -842.000000) translate(0.000000, 818.000000)'><polygon id='long-arrow-right-copy-2' points='32.105 22.988 27.012 17.723 27.712 17 34 23.5 27.712 30 27.012 29.277 32.105 24.012 14 24.012 14 22.988'></polygon></g></g></g></g></svg>");
        right: auto;
        left: u(0);
    }
    
    .Slider-button--next,
    .Slider--rtl .Slider-button--prev {
        background-image: svg-url("<svg width='20px' height='13px' viewBox='0 0 20 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='DESIGN' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='02_diensten' transform='translate(-922.000000, -591.000000)' fill='#{$black}' fill-rule='nonzero'><g id='werkbaar-werk' transform='translate(741.000000, 573.000000)'><polygon id='long-arrow-right-copy' points='199.13648 23.988 194.04348 18.723 194.74348 18 201.03148 24.5 194.74348 31 194.04348 30.277 199.13648 25.012 181.03148 25.012 181.03148 23.988'></polygon></g></g></g></svg>");
        right: u(0);
        left: auto;
    }
}
