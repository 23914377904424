/* Link */
a {
    @include transition(#{background-color, color});
    color: $link-color;
    text-decoration: $link-text-decoration;
    text-decoration-thickness: 1px !important;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: $black;

    &:hover,
    &:focus,
    &:active {
        color: $link-color--hover;
        text-decoration: $link-text-decoration--hover;
    }
}
