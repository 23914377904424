/* List */
ol,
ul {
    margin-bottom: u($spacing-unit);
    padding-left: u($list-gutter--l);

    li {
        margin-bottom: u(5);
        padding-left: 20px;
        // list-style-type: disc;
        list-style-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='21' height='16' viewBox='0 0 21 16'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M7.875,0a1.3,1.3,0,0,1-.928-.391L.385-7.058a1.347,1.347,0,0,1,0-1.885,1.3,1.3,0,0,1,1.856,0L7.826-3.269l10.887-12.29a1.3,1.3,0,0,1,1.853-.1,1.349,1.349,0,0,1,.1,1.883L8.851-.441A1.307,1.307,0,0,1,7.91,0Z' fill='%23da1024' clip-rule='evenodd'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath d='M0,0H21V-16H0Z' fill='%23da1024'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(0 16)' clip-path='url(%23a)'%3E%3Cg clip-path='url(%23b)'%3E%3Cpath d='M-5-21H26V5H-5Z' fill='%23da1024'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        // padding-left: 10px;
    }

    ol,
    ul {
        margin-top: u(4);
        margin-bottom: u(4);
    }
}

// Unordered List
ul {
    list-style-type: disc;
}

ul ul {
    list-style-type: circle;
}

ul ul ul {
    list-style-type: square;
}

// Ordered List
ol {
    list-style-type: decimal;
}

ol ol {
    list-style-type: upper-alpha;
}

ol ol ol {
    list-style-type: lower-roman;
}

ol ol ol ol {
    list-style-type: lower-alpha;
}

// Definition List
dt {
    font-weight: 700;
}