.Privacy {
    margin: 120px auto;
    width: 960px;

    @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 100px;
        margin-bottom: 50px;
    }

    &-title {
        margin-bottom: 30px;
        color: $white;
    }

    &-subtitle {
        color: $white;
    }

    &-text {
        font-size: 16px;
        color: $white;
        margin-bottom: 50px;

        a {
            color: $cc-red;

            &:hover, &:active, &:focus {
                color: $cc-grey;
            }
        }

        strong {
            color: $white;
        }

        ul {
            list-style-type: disc !important;
        }

        li {
            color: $white;
            list-style-image: unset;
        }
    }

    &-back {
        display: flex;
        align-items: center;

        color: $white;
        font-weight: 700;
        
        a {
            margin-bottom: 0;
        }

        svg {
            margin-right: 10px;
            transition: transform .3s ease;
        }

        &:hover, &:focus, &:active {
            svg {
                animation: leftRight 1s ease infinite;
                -webkit-animation: leftRight 1s ease infinite;
            }
        }
    }
}

@keyframes leftRight {
    0%,100%{
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
    }
    50%{
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }
}
