/* Colors: Custom */

//Example: $cc-[NAME]: #......

// Garage ClaudeBal colors

$cc-black: #050505 !default;
$cc-darkGrey: #1f1f1f!default;
$cc-grey: #696969!default;
$cc-lightestGrey: #e1e1e1 !default;
$cc-red: #da1024 !default;
