/* Logo */
.Logo {
    position: relative;
    z-index: 10;

    @include mq($from: 401px, $until: 1310px, $media-type: $mq-type, $mq_debug: null) {
        svg {
            width: 250px;
            height: auto;
        }
    } 

    @include mq($until: 400px, $media-type: $mq-type, $mq_debug: null) {
        svg {
            width: 180px;
            height: auto;
        }
    } 
}
