.Banner {
    position: relative;
    width: 100vw;
    height: 100vh;

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        height: 100vh;
    }

    &-images {
        width: 100%;
        height: 100%;

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            height: 100%;
        }
    }
    
    &-image {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: rgba($color: $black, $alpha: 0.3);
        background-blend-mode: multiply;
        width: 100vw;
        height: 100vh;

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            height: 100vh;
        }

        &--info {
            position: absolute;
            bottom: 70px;
            left: 320px;
            color: $white;
            font-size: 18px;
            line-height: 32px;
            font-weight: 600;

            @include mq($from: 1440px, $until: 1600px, $media-type: $mq-type, $mq_debug: null) {
                left: 160px;
            }

            @include mq($from: 769px, $until: 1439px, $media-type: $mq-type, $mq_debug: null) {
                left: 50px;
            }

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                left: 20px;
                bottom: 150px;
                height: 100vh;
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    &-title {
        position: absolute;
        top: 40%;
        left: 320px;
        color: $white;
        font-size: 56px;
        font-weight: 700;
        line-height: 65px;
        margin-bottom: 0;
        width: 640px;
        overflow: hidden;
        opacity: 0;
        transition: opacity .1s ease;

        &.show {
            opacity: 1 !important;
        }

        @include mq($from: 1440px, $until: 1600px, $media-type: $mq-type, $mq_debug: null) {
            left: 260px;
        }

        @include mq($from: 769px, $until: 1439px, $media-type: $mq-type, $mq_debug: null) {
            left: 150px;
            font-size: 36px;
            line-height: 45px;
        }

        @include mq($from: 361px, $until: 768px, $media-type: $mq-type, $mq_debug: null) {
            left: 20px;
            font-size: 28px;
            line-height: 35px;
            width: auto;
        }

        @include mq($until: 360px, $media-type: $mq-type, $mq_debug: null) {
            left: 20px;
            font-size: 25px;
            line-height: 30px;
            hyphens: auto;
            width: 100%;
        }

        p {
            margin-bottom: 0;
        }
    }

    &-number {
        position: absolute;
        top: 40%;
        left: 160px;
        color: $cc-red;
        font-weight: 700;
        font-size: 24px;
        font-family: $font-ProximaNova;

        @include mq($from: 1440px, $until: 1600px, $media-type: $mq-type, $mq_debug: null) {
            left: 160px;
        }

        @include mq($from: 769px, $until: 1439px, $media-type: $mq-type, $mq_debug: null) {
            left: 50px;
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            left: 20px;
            top: 32%;
        }
    }

    &-cta {
        position: absolute;
        bottom: 70px;
        right: 150px;
        height: 50px;


        @include mq($from: 769px, $until: 1439px, $media-type: $mq-type, $mq_debug: null) {
            right: 50px;
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            right: unset;
            left: 20px;
            bottom: 30px;
        }
    }
}