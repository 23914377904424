/* General */

// // LazySizes
// .lazyload,
// .lazyloading {
//     opacity: 0;
// }

// .lazyloaded {
//     @include transition(opacity, 300ms);
//     opacity: 1;
// }

html {
    background-color: $cc-black;
}

// Tabbing
body:not(.is-tabbing) {

    span:focus,
    button:focus,
    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
    }
}

.Main {
    position: relative;
    // overflow: hidden;
}

.BackButton {
    
    a {
        font-family: $font-ProximaNova;
        font-weight: 600;
        color: $cc-grey;
        margin-bottom: 20px;

        &:hover {
            color: $white;
        }
    }

    @include mq($from: 769px,$until: 1600px, $media-type: $mq-type, $mq_debug: null) {
        margin-left: 50px;
    }

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        margin-left: 20px;
    }
}

.split-child {
    overflow: hidden;
}