.Thanks {
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    margin-bottom: 160px;
    width: 960px;
    text-align: center;

    @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 200px;
    }

    &-title {
        margin-bottom: 30px;
        color: $white;
    }

    &-text {
        color: $white;
        font-size: 20px;
        margin-bottom: 50px;

        a {
            color: $cc-red;

            &:hover, &:active, &:focus {
                color: $white;
            }
        }

        strong {
            color: $white;
        }
    }
}