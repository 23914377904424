.OfferContainer {
  max-width: 1600px;
  margin: 200px auto 0;

  @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
    margin-top: 100px;
}
}

.OfferDetail {
    position: relative;
    display: grid;
    grid-template-columns: 960px 625px;
    grid-template-areas: 'content info';
    grid-gap: 16px;
    justify-content: center;
    margin-bottom: 120px;
    height: max-content;

    @include mq($from: 1301px,$until: 1600px, $media-type: $mq-type, $mq_debug: null) {
        grid-template-columns: 660px 625px;
    }

    @include mq($from: 1201px,$until: 1300px, $media-type: $mq-type, $mq_debug: null) {
        grid-template-columns: 560px 625px;
    }

    @include mq($until: 1200px, $media-type: $mq-type, $mq_debug: null) {
        grid-template-columns: 100%;
        grid-template-areas: 
        'thumbnail'
        'info'
        'content';
    }

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        margin-bottom: 50px;
    }

    &-images {
        grid-area: content;

        @include mq($from: 769px, $until: 1200px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 20px;
        }

        .Images-container {

            @include mq($from: 769px, $until: 1200px, $media-type: $mq-type, $mq_debug: null) {
                display: flex;
            }
    
            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }


        &--thumbnail {
            position: relative;
            width: 100%;
            height: 672px;
            margin-bottom: 16px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            &:hover {
                .HoverOffer {
                    opacity: 1;
                }
            }

            @include mq($until: 1200px, $media-type: $mq-type, $mq_debug: null) {
                display: none;
            }

            &__mobile {
                grid-area: 'thumbnail';
                display: none;
                position: relative;
                width: 100%;
                height: 672px;
                margin-bottom: 16px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                @include mq($until: 1200px, $media-type: $mq-type, $mq_debug: null) {
                    display: block;
                    height: 430px;
                    margin-right: 16px;
                    margin-bottom: 0;
                }
    
                @include mq($from: 736px, $until: 768px, $media-type: $mq-type, $mq_debug: null) {
                    display: block;
                    margin-right: 16px;
                    width: auto; 
                    margin-left: 0;
                }
    
                @include mq($from: 555px, $until: 735px, $media-type: $mq-type, $mq_debug: null) {
                    display: block;
                    width: 548px;
                    height: 320px;
                    margin-left: 0;
                    margin-right: 0 !important;
                }
    
                @include mq($until: 554px, $media-type: $mq-type, $mq_debug: null) {
                    display: block;
                    width: auto;
                    height: 320px;
                    margin-left: 0;
                    margin-right: 0 !important;
                }
            }
        }

        &--detail {
            grid-area: info;
            display: grid;
            grid-template-columns: repeat(3, 309px);
            grid-gap: 16px;
            margin-bottom: 160px;

            @include mq($from: 1301px,$until: 1600px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(3, 209px);
            }

            @include mq($from: 1201px,$until: 1300px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(3, 176px);
            }

            @include mq($from: 1100px, $until: 1200px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(5, 200px);
                margin-left: 16px;
                margin-right: 16px;
            }

            @include mq($from: 890px, $until: 1099px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(4, 200px);
                margin-left: 16px;
                margin-right: 16px;
                margin-bottom: 50px;
            }

            @include mq($from: 768px, $until: 889px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(3, 200px);
                margin-left: 16px;
                margin-right: 16px;
                margin-bottom: 50px;
            }

            @include mq($from: 736px, $until: 768px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(3, 172px);
                margin-left: 16px;
                margin-right: 16px;
                margin-top: 16px;
                margin-bottom: 50px;
            }

            @include mq($from: 551px, $until: 735px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(3, 172px);
                margin-left: 16px;
                margin-right: 16px;
                margin-top: 16px;
                margin-bottom: 50px;
            }

            @include mq($from: 360px, $until: 550px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(2, 172px);
                margin-left: 16px;
                margin-right: 16px;
                margin-top: 16px;
                margin-bottom: 50px;
            }

            @include mq($from: 330px, $until: 360px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(3, 100px);
                margin-left: 16px;
                margin-right: 16px;
                margin-top: 16px;
                margin-bottom: 50px;
            }

            @include mq($until: 329px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(2, 100px);
                margin-left: 16px;
                margin-right: 16px;
                margin-top: 16px;
                margin-bottom: 50px;
            }
        

           &__image {
               position: relative;
               width: 100%;
               height: 200px;

               img {
                   width: 100%;
                   height: 100%;
                   object-fit: cover;
               }

               &:hover {
                    .HoverOffer {
                        opacity: 1;
                    }
                }

                @include mq($from: 1201px,$until: 1600px, $media-type: $mq-type, $mq_debug: null) {
                    height: 150px;
                }

                @include mq($from: 550px, $until: 1200px, $media-type: $mq-type, $mq_debug: null) {
                    height: 133px;
                }

                @include mq($from: 361px, $until: 550px, $media-type: $mq-type, $mq_debug: null) {
                    height: 110px;
                }

                @include mq($until: 360px, $media-type: $mq-type, $mq_debug: null) {
                    height: 60px;
                }
            }
        }
    }

    .HoverOffer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($color: $cc-darkGrey, $alpha: 0.8);
        opacity: 0;
        transition: opacity .3s ease;
    }

    &-info {
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        background-color: $cc-darkGrey;
        padding: 60px;
        height: max-content;

        @include mq($until: 1200px, $media-type: $mq-type, $mq_debug: null) {
            margin-right: 16px;
            margin-left: 16px;
            position: unset;
        }

        @include mq($until: 399px, $media-type: $mq-type, $mq_debug: null) {
            padding: 30px 20px;
            position: unset;
        }
    }

    &-title {
        color: $white;
        margin-bottom: 0;
        font-size: 36px;
        font-weight: 700;

        @include mq($until: 520px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 28px;
        }
    }

    &-price {
        color: $cc-red;
        font-weight: 700;
        font-size: 22px;
        font-family: $font-ProximaNova;
        margin-bottom: 0;

        @include mq($until: 520px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 18px;
        }
    }

    &-data {
        margin-top: 60px;
        margin-bottom: 60px;

        @include mq($until: 399px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &--container {
            display: grid;
            grid-template-columns: 160px 310px;
            align-items: center;
            grid-gap: 30px;
            border-bottom: solid 1px $cc-grey;
            padding: 10px 0;

            &:last-child {
                border-bottom: none;
            }

            @include mq($from: 441px, $until: 520px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: 160px 220px;
            }

            @include mq($from: 400px, $until: 440px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: 160px 150px;
            }

            @include mq($until: 399px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: 1fr;
                grid-gap: 10px;
            }
        }

        &-title {
            color: $white;
            font-weight: 800;
            line-height: 24px;
            margin-bottom: 0;
        }

        &-value {
            color: $white;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;
        }
    }

    &-buttons {
        a {
            margin-right: 30px;

            @include mq($until: 500px, $media-type: $mq-type, $mq_debug: null) {
                margin-bottom: 20px;
            }

            &:last-child {
                margin-right: 0;

                @include mq($until: 500px, $media-type: $mq-type, $mq_debug: null) {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.OfferOptions {
    grid-area: content;
    margin-bottom: 140px;

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 50px;
    }

    &-title {
        color: $white;
        font-size: 36px;
        font-weight: 700;
        line-height: 42px;
        margin-bottom: 0;

        @include mq($until: 520px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 28px;
        }
    }

    &-line {
        width: 80px;
        height: 1px;
        background-color: $cc-red;
        margin-top: 40px;
        margin-bottom: 50px;

        @include mq($until: 520px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    &-options {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px 50px;

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 1fr;
        }

        p {
            color: $white;
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
}

.OfferForm {
    grid-area: content;
    margin-bottom: 160px;

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 50px;
    }


    &-title {
        max-width: 800px;
        color: $white;
        font-size: 36px;
        font-weight: 700;
        line-height: 42px;
        margin-bottom: 0;

        @include mq($until: 520px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 28px;
        }
    }

    &-line {
        width: 80px;
        height: 1px;
        background-color: $cc-red;
        margin-top: 40px;
        margin-bottom: 40px;

        @include mq($until: 520px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    &-text {
        max-width: 800px;
        color: $white;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 0;

    }

    &-contact {
        max-width: 800px;
        color: $white;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 45px;

        p {
            margin-bottom: 0;

            strong {
                color: $cc-red;
            }

            a {
                color: $white;
    
                &:hover {
                    color: $cc-grey;
                }
            }
        }
    }

    &-top {
        margin-top: 120px;

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 50px;
        }
    }

    .Form {
        max-width: 800px;

        &-label {
            display: none;
        }

        &-field, &--input {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            padding: 8px 0;
            background-color: transparent !important;
            border-radius: 0;
            border-top: hidden;
            border-left: hidden;
            border-right: hidden;
            border-bottom: 2px solid $cc-grey;
            font-size: 14px;
            font-weight: 600;
            color: $cc-red;
            margin-bottom: 24px;

            &:active, &:focus {
                border-bottom: 2px solid $cc-red;

                &::placeholder {
                    color: $white;
                    font-size: 14px;
                    font-weight: 600;
                    text-align: right;
                }
            }

            &::placeholder {
                color: $white;
                font-size: 14px;
                font-weight: 600;
            }
        }

        &-item--action {
            display: flex;
            justify-content: flex-end;
        }
    }
}