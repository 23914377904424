/* Button */

.Button {

    // Primary
    &--primary {
        background-color: $cc-red;
        border: solid 1px $cc-red;
        border-radius: 50px;
        color: $white;
        font-size: u(16);
        font-family: $font-ProximaNova;
        font-weight: 600;
        transition: background-color .1s ease, border .1s ease,  color .1s ease;
        width: max-content;
        padding: 15px 20px;
        text-transform: uppercase;
        letter-spacing: 1px;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: transparent;
                border: solid 1px $cc-red;
                color: $cc-red;
            }
        }

        &.Button--outline {
            background-color: transparent;
            border: solid 1px $white;
            color: $white;
            font-size: u(14);
            font-family: $font-ProximaNova;
            font-weight: 600;
            transition: border .1s ease, background-color .1s ease , color .1s ease, fill .1s ease;
            border-radius: 0;

        
            &:active,
            &:focus,
            &:hover,
            &.is-active {
                transition: border .1s ease, background-color .1s ease, color .1s ease;
                border: solid 1px $white;
                background-color: $white;
                color: $cc-black;
            }
        }
    }

    // Secondary
    &--secondary {
        background-color: $cc-red;
        border: solid 1px $cc-red;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: u(14);
        font-family: $font-ProximaNova;
        font-weight: 600;
        transition: background-color .1s ease, border .1s ease,  color .1s ease;
        width: max-content;
        padding: 15px 20px;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: transparent;
                border: solid 1px $cc-red;
                color: $white;
            }
        }

        &.Button--outline {
            background-color: transparent;
            border: solid 1px $white;
            color: $white;
            font-size: u(16);
            // font-family: $font-CrimsonText;
            font-weight: 400;
            transition: border .1s ease, background-color .1s ease , color .1s ease, fill .1s ease;
            border-radius: 0;

        
            &:active,
            &:focus,
            &:hover,
            &.is-active {
                transition: border .1s ease, background-color .1s ease, color .1s ease;
                border: solid 1px $white;
                background-color: $white;
                color: $white;
            }
        }
    }

    &--black {
        background-color: transparent;
        border: solid 2px $black;
        border-radius: u(50);
        color: $black;
        font-size: u(16);
        font-weight: 700;
        padding: 15px 20px;
        transition: background-color .1s ease, border .1s ease,  color .1s ease;
        width: max-content;

        svg {
            path {
                transition: stroke .1s ease, fill .1s ease;
                stroke: $black;
                fill: $black;
            }
        }

        &:active,
        &:focus,
        &:hover {
            background-color: $black;
            border: solid 2px $black;
            color: $white;

            svg {
                path {
                    stroke: $white;
                    fill: $white;
                }
            }
        }
    }

    &-red {
        position: relative;
        color: $white;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        padding-left: 81px;
        padding-right: 25px;
        z-index: 50;
        width: max-content;
        height: 58px;

        @include mq($until: 400px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 16px;
        }

        &::before {
            content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='13' viewBox='0 0 20 13'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M18.1-7.012l-5.093-5.265.7-.723L20-6.5,13.712,0l-.7-.723L18.1-5.988H0V-7.012Z' fill='%23fff'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath d='M0,0H20V-13H0Z' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(0 13)' clip-path='url(%23a)'%3E%3Cg clip-path='url(%23b)'%3E%3Cpath d='M-5-18H25V5H-5Z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");;
            position: absolute;
            left: 0;
            z-index: -1;
            width: 56px;
            height: 56px;
            padding: 14px 18px;
            background-color: $cc-red;
            transition: width .3s ease;
        }

        &:hover {
            color: $white;

            &:before {
                width: 100%;
            }
        }

    }
}