.InfoBlock {
    display: grid;
    grid-template-columns: 840px 562px;
    grid-gap: 80px;

    max-width: 1480px;
    margin: 160px auto 0;

    @include mq($from: 1450px, $until: 1500px, $media-type: $mq-type, $mq_debug: null) {
        grid-gap: 30px;
    }

    @include mq($from: 1231px, $until: 1449px, $media-type: $mq-type, $mq_debug: null) {
        grid-template-columns: 640px 562px;
        grid-gap: 30px;
        justify-content: center;
    }

    @include mq($from: 769px, $until: 1230px, $media-type: $mq-type, $mq_debug: null) {
        grid-template-columns: 100%;
        grid-gap: 30px;
    }

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        grid-template-columns: 100%;
        grid-gap: 30px;
        margin: 50px auto 0;
    }

    &-left {
        height: max-content;

        @include mq($from: 769px, $until: 1230px, $media-type: $mq-type, $mq_debug: null) {
            display: flex;
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            display: block;
        }

        &--wrapper {
            display: grid;
            grid-template-columns: 240px 602px;

            @include mq($from: 1231px, $until: 1449px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: 1fr 500px;
            }

            @include mq($from: 1024px, $until: 1230px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: 620px;
            }

            @include mq($from: 769px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: 420px;
            }

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: 100%;
            }
        }
    }

    &-right {
        height: max-content;
        margin-top: 125px;

        @include mq($from: 1231px, $until: 1449px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 50px;
        }

        @include mq($from: 769px, $until: 1230px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 50px;
            display: flex;
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 0px;
            display: flex;
            flex-direction: column-reverse;
        }

        
        a {
            @include mq($from: 769px, $until: 1230px, $media-type: $mq-type, $mq_debug: null) {
                margin-left: 50px;
                margin-top: 14px;
                margin-bottom: 50px;
            }

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                margin-left: 20px;
                margin-top: 30px;
                margin-bottom: 50px;
            }
        }
    }

    &-subtitle {
        position: relative;
        
        h1 {
            position: absolute;
            right: 75px;
            color: $cc-darkGrey;
            font-size: 56px;
            font-weight: 700;
            margin: 0;
            white-space: nowrap;
            transform: translateY(85px) rotate(270deg);
            transform-origin: right;

            @include mq($from: 1231px, $until: 1449px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 40px;
            }

            @include mq($from: 1024, $until: 1230px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 40px;
                transform: unset;
                position: relative;
                right: unset;
                margin-left: 50px;
            }

            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                display: none;
            }
        }
    }

    &-title {
        color: $white;
        margin-bottom: 0;
        font-size: 36px;
        font-weight: 700; 
        line-height: 42px;

        p {
            margin-bottom: 0;
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 28px;
        }
    }

    &-text {
        color: $white;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 37px;

        ul {
            margin-top: 200px;

            @include mq($from: 1231px, $until: 1449px, $media-type: $mq-type, $mq_debug: null) {
                margin-top: 50px;
            }

            @include mq($from: 1201px, $until: 1230px, $media-type: $mq-type, $mq_debug: null) {
                margin-top: 50px;
                margin-left: 50px;
            }

            @include mq($until: 1200px, $media-type: $mq-type, $mq_debug: null) {
                margin-top: 0px;
                margin-left: 20px;
                margin-right: 20px;
            }
            

            li {
                margin-bottom: 20px;

                &:nth-last-child() {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-line {
        background-color: $cc-red;
        width: 80px;
        height: 1px;
        margin-top: 50px;
        margin-bottom: 50px;

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    &-infoLeft {
        background-color: $cc-darkGrey;
        padding: 125px 90px 90px 110px;
        margin-bottom: 80px;

        @include mq($from: 1231px, $until: 1449px, $media-type: $mq-type, $mq_debug: null) {
            padding: 50px 50px;
        }

        @include mq($from: 769px, $until: 1230px, $media-type: $mq-type, $mq_debug: null) {
            padding: 50px 50px;
            margin-bottom: 0;
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            padding: 50px 20px;
            margin-bottom: 0;
        }
    }

    &-image {

        &--left {
            width: 100%;
            height: 593px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include mq($from: 769px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                height: auto;
            }

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                height: 320px;
            }
        }

        &--right {
            width: 100%;
            height: 805px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include mq($from: 769px, $until: 1230px, $media-type: $mq-type, $mq_debug: null) {
                height: auto;
                width: 50%;
            }

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                height: 320px;
            }

            &--bottom {
                margin-bottom: 80px;

                @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.AboutPage, .RacingTeamPage {
    .InfoBlock {
        margin-bottom: 160px;

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            margin-bottom: 50px;
        }
    }
}

.RacingTeamPage {
    .InfoBlock {
        &-subtitle {
            
            h1 {
                transform: translateY(-45px) rotate(270deg);


                @include mq($from: 1231px, $until: 1449px, $media-type: $mq-type, $mq_debug: null) {
                    transform: translateY(-30px) rotate(270deg);
                }

                @include mq($until: 1230px, $media-type: $mq-type, $mq_debug: null) {
                    transform: unset;
                }
            }
        }
    }
}