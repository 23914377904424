.Header {
  background-color: transparent;
  width: 100%;
  position: absolute;
  top: 0;
  padding: 40px 160px;
  border-top: 5px solid #e42432;

  @include mq($from: 1024px, $until: 1600px, $media-type: $mq-type, $mq_debug: null) {
     padding: 40px 50px;
  } 

  @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
    padding: 30px 20px;
 } 
}

.HeaderColor {
  background-color: $cc-darkGrey;
  transition: background-color 1.5s ease;
}