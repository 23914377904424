/* Nav */
.Nav {

    @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
        margin-top: 50px;
    } 

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
        list-style-type: none;

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        flex: 0 1 auto;

        @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
        } 
    }

    &-item {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-link {
        position: relative;
        user-select: none;
        display: block;
        font-size: 16px;
        color: $white;
        font-weight: 800;
        text-decoration: none;
        text-transform: uppercase;
        margin-left: 65px;

        @include mq($from: 1025px, $until: 1200px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 30px;
        } 

        @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 0;
            margin-bottom: 25px;
        } 

        &:hover, &:focus, &:active {
            text-decoration: none;
            color: $cc-red;
        }

        &.is-active {
            position: relative;
            color: $cc-red;

            @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
                color: $cc-red !important;
            } 
        }
    }

    &-label {
        font-family: $font-ProximaNova;
        font-weight: 800;
    }
}
