.Nav--footer {

    .Nav-list {
        margin-left: u($spacing-unit--sm * -1);
    }

    .Nav-item {
        padding-left: u($spacing-unit--sm);
    }
}
