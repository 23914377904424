.CallToActionBlock {
    display: grid;
    grid-template-columns: repeat(2, 640px);
    max-width: 1280px;
    margin: 160px auto;

    @include mq($from: 1090px, $until: 1290px, $media-type: $mq-type, $mq_debug: null) {
        grid-template-columns: repeat(2, 540px);
        justify-content: center;
    }

    @include mq($from: 881px, $until: 1089px, $media-type: $mq-type, $mq_debug: null) {
        grid-template-columns: repeat(2, 440px);
        justify-content: center;
    }

    @include mq($from: 641px, $until: 880px, $media-type: $mq-type, $mq_debug: null) {
        grid-template-columns: 640px;
        justify-content: center;
    }

    @include mq($until: 641px, $media-type: $mq-type, $mq_debug: null) {
        grid-template-columns: 100%;
        justify-content: center;
        margin: 50px auto;
    }

    &-image {
        width: 100%;
        height: 720px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        // @include mq($from: 1090px, $until: 1290px, $media-type: $mq-type, $mq_debug: null) {
        //     height: 520px;
        // }

        @include mq($from: 881px, $until: 1089px, $media-type: $mq-type, $mq_debug: null) {
            height: auto;
            min-height: 500px;
        }

        @include mq($until: 880px, $media-type: $mq-type, $mq_debug: null) {
            height: 320px;
        }
    }

    &-content {
        height: 100%;
        background-color: $cc-darkGrey;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 160px;
        padding-right: 130px;

        @include mq($from: 1090px, $until: 1290px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 100px;
            padding-right: 100px;
        }

        @include mq($from: 881px, $until: 1089px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 50px;
            padding-right: 50px;
            padding-top: 30px;
            padding-bottom: 30px;
        }

        @include mq($until: 880px, $media-type: $mq-type, $mq_debug: null) {
            padding: 50px 20px;
            
        }
    }

    &-title {
        color: $white;
        margin-bottom: 0;
        font-size: 36px;
        font-weight: 700; 
        line-height: 42px;

        p {
            margin-bottom: 0;
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 28px;
        }
    }

    &-text {
        color: $white;
        font-size: 16px;
        font-weight: 500;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-button--cta {
        margin-bottom: 37px;
    }

    &-line {
        background-color: $cc-red;
        width: 80px;
        height: 1px;
        margin-top: 50px;
        margin-bottom: 50px;

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    .Button-red {
        margin-top: 15px;

        &:first-child {
            margin-top: 0;
        }
    }

}