.Parallax {
    width: 100vw;
    height: 840px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    @include mq($from: 769px, $until: 1500px, $media-type: $mq-type, $mq_debug: null) {
        height: 500px;
    }

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        height: 320px;
        background-attachment: unset;
    }
}