.Contact {
    max-width: 1600px;
    margin: 200px auto 0;


    @include mq($from: 769px, $until: 1229px, $media-type: $mq-type, $mq_debug: null) {
        margin: 150px 50px 0;
    }

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        margin: 100px 20px 0;
    }

    &-container {
        display: grid;
        grid-template-columns: 640px 800px;
        grid-gap: 160px;
        justify-content: center;

        @include mq($from: 1230px, $until: 1600px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 640px 500px;
            grid-gap: 80px;
        }

        @include mq($until: 1229px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 1fr;
            grid-gap: 50px;
        }

    }

    &-responsive {
        @include mq($from: 831px, $until: 1229px, $media-type: $mq-type, $mq_debug: null) {
            display: flex;
            justify-content: space-between;
        }

        @include mq($until: 830px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
        }
    }
    
    &-wrapper {
        position: relative;
        z-index: 9999;
        pointer-events: none;
    }

    &-title {
        color: $white;
        text-align: center;
        margin-bottom: 120px;

        @include mq($until: 1229px, $media-type: $mq-type, $mq_debug: null) {
            margin-bottom: 50px;
        }
    }

    &-subtitle {
        color: $white;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    &-data {
        color: $white;
        margin-bottom: 30px;

        p {
            margin-bottom: 0;
        }
    }

    &-opening {

    }

    &-link {
        color: $white;

        &:hover {
            color: $cc-grey;
        }
    }

    &-info {
        background-color: $cc-darkGrey;
        width: 100%;
        padding: 80px;
        pointer-events: all;

        @include mq($until: 599px, $media-type: $mq-type, $mq_debug: null) {
            padding: 30px;
        }

        p {
            color: $white;
            font-size: 16px;
            font-weight: 600;

            strong {
                text-transform: uppercase;
                color: $cc-red;
                font-size: 16px;
                margin-bottom: 0;
                letter-spacing: 1px;
                font-weight: 600;
            }

            em {
                color: $cc-grey;
                font-style: normal;
                font-size: 16px;

                a {
                    color: $cc-grey;
                    transition: color .3s ease;
                    text-decoration: none;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }

    &-form {
        color: $white;

        .Form {

            &-label {
                display: none;
            }

            &-field, &--input {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 100%;
                padding: 8px 0;
                background-color: transparent !important;
                border-radius: 0;
                border-top: hidden;
                border-left: hidden;
                border-right: hidden;
                border-bottom: 2px solid $cc-grey;
                font-size: 14px;
                font-weight: 600;
                color: $cc-red;
                margin-bottom: 24px;

                &:active, &:focus {
                    border-bottom: 2px solid $cc-red;

                    &::placeholder {
                        color: $white;
                        font-size: 14px;
                        font-weight: 600;
                        text-align: right;
                    }
                }

                &::placeholder {
                    color: $white;
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            &-item--action {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}