/* Lang */
.Nav--lang {
    @include position(relative, $z: 1);
    margin-left: 15px;

    &:not(:hover) {
        .Nav-list {
            opacity: 0;
            visibility: hidden;
        }
    }

    // Toggle
    .Nav-toggle {
        cursor: default;
        align-items: center;
        display: flex;
        color: $white;
        font-size: 12px;
        // font-family: $font-Gotham;
        font-weight: 400;
        text-transform: uppercase;

        svg {
            path {
                fill: $white;
            }
        }
    }

    // List
    .Nav-list {

        @include transition(#{opacity, visibility});
        @include position(absolute, $top: 100%, $right: 0);
        color: $black;
        background-color: $white;
        flex-direction: column;
        padding: 5px 10px;
    }

    .Nav-item {
        height: auto;
        padding: u(10) 0;
        margin-bottom: 0;
    }

    // Link
    .Nav-link {
        color: $black;
        transition: color .2s ease, text-decoration .2s ease;
        margin-left: 0;
        font-size: 16px;
        // font-family: $font-Gotham;
        font-weight: 400;


        &::after {
            display: none;
        }

        &:hover, &:active, &:focus {
            color: $cc-red;
        }

        &.active {
            color: $cc-red;
            font-weight: 700;
        }
    }
}
