.Footer {
    display: flex;
    justify-content: space-between;
    padding: 70px 160px;
    border-top: $cc-grey solid 1px;

    @include mq($from: 769px, $until: 1200px, $media-type: $mq-type, $mq_debug: null) {
        padding: 50px 50px;
    }

    @include mq($from: 831px, $until: 870px, $media-type: $mq-type, $mq_debug: null) {
        padding: 50px 20px;
    }

    @include mq($until: 830px, $media-type: $mq-type, $mq_debug: null) {
        padding: 50px 20px;
        flex-direction: column;
    }

    &-container {
        display: grid;
        grid-template-columns: 480px 330px 160px;

        @include mq($from: 1260px, $until: 1500px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns:  350px 260px 160px;
        }

        @include mq($from: 831px, $until: 1259px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns:  250px 260px 160px;
        }

        @include mq($until: 830px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 100%;
        }
    }

    &-title {
        color: $cc-red;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    &-logo {
        svg {
            height: auto;
            width: 285px;

            @include mq($until: 1280px, $media-type: $mq-type, $mq_debug: null) {
                width: 200px;
            }
        }

        @include mq($until: 830px, $media-type: $mq-type, $mq_debug: null) {
            margin-bottom: 50px;
        }
    }

    &-data {
        font-family: $font-ProximaNova;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        // margin-bottom: 7px;
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-contact {
        display: flex;
        flex-direction: column;
        margin-top: 37px;

        a {
            font-family: $font-ProximaNova;
        }

        @include mq($until: 830px, $media-type: $mq-type, $mq_debug: null) {
            margin-bottom: 30px;
        }
    }

    &-link {
        font-family: $font-ProximaNova;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        // margin-bottom: 7px;
        margin-bottom: 0;

        &:nth-last-child() {
            margin-bottom: 0;
        }

        &:hover {
            color: $cc-grey;
        }
    }

    .Nav {
        margin-top: 0;
    }

    .Nav-link {
        margin-left: 0;
        font-weight: 600;
        margin-bottom: 0;
    }

    .Nav-label {
        font-weight: 600;
    }

    &-nav {

        .Nav-list {
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }

        .Nav-item {
            padding: 0;
            // margin-bottom: 7px;
            margin-bottom: 0;
        }

        .Nav-link {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 24px;

            &.is-active {
                color: $white;

                @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
                    color: $white !important;
                } 
            }

            &:hover {
                color: $cc-grey;
            }
        }

    }

    &-socials {
        display: flex;
        align-items: center;

        p {
            margin-bottom: 0;
            color: $white;
            font-weight: 700;
            letter-spacing: 1px;
            margin-right: 10px;
        }

        a {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        svg {
            height: 15px;
            width: auto;
            
            path {
                transition: fill .3s ease;

                &:hover {
                    fill: $cc-red;
                }
            }
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include mq($until: 830px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 30px;
        }
    }

    &-privacy {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include mq($until: 830px, $media-type: $mq-type, $mq_debug: null) {
            align-items: flex-start;
        }

        .nav--permission {
            .Nav-list {
                justify-content: flex-start;
                flex-direction: row;
            }
    
            .Nav-item {
                padding: 0;
                margin-bottom: 0;
                font-weight: 300;
            }
    
            .Nav-link {
                font-family: $font-ProximaNova;
                text-transform: capitalize;
                font-weight: 300;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: 24px;
                color: $cc-grey;

                &:hover {
                    color: $white;
                }
            }

            .Nav-label {
                font-family: $font-ProximaNova;
                font-weight: 300;
                letter-spacing: 1px;
                line-height: 24px;
                color: $cc-grey;
                transition: color .3s ease;

                &:hover {
                    color: $white;
                }
            }
        }

        .Moqo {
            font-family: $font-ProximaNova;
            font-weight: 300;
            color: $cc-grey;
            font-size: 14px;
            letter-spacing: 1px;

            &:hover {
                color: $white;
            }
        }
    }
}