.Home-offers {
    max-width: 1600px;
    margin: 160px auto;

    @include mq($from: 1350px, $until: 1600px, $media-type: $mq-type, $mq_debug: null) {
        width: 1300px;
    }

    @include mq($from: 1130px, $until: 1349px, $media-type: $mq-type, $mq_debug: null) {
        width: 1110px;
    }

    @include mq($from: 1030px, $until: 1129px, $media-type: $mq-type, $mq_debug: null) {
        width: 1030px;
    }

    @include mq($from: 830px, $until: 1029px, $media-type: $mq-type, $mq_debug: null) {
        width: 830px;
    }

    @include mq($from: 768px, $until: 829px, $media-type: $mq-type, $mq_debug: null) {
        width: 730px;
    }

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        margin: 50px auto
    }

    &--content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mq($from: 501px, $until: 767px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 120px;
            margin-bottom: 50px;
        }

        @include mq($until: 500px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 20px;
            margin-bottom: 50px;
        }

        h2 {
            color: $white;
            font-weight: 700;
            font-size: 36px;
            line-height: 42px;
            margin-bottom: 0;
        }

        a {
            height: 58px;
        }
    }

    &--line {
        background-color: $cc-red;
        width: 80px;
        height: 1px;
        margin-top: 40px;
        margin-bottom: 80px;

        @include mq($from: 501px, $until: 767px, $media-type: $mq-type, $mq_debug: null) {
            display: none;
        }

        @include mq($until: 500px, $media-type: $mq-type, $mq_debug: null) {
            display: none;
        }

        &__mobile {
            display: none;
            background-color: $cc-red;
            width: 80px;
            height: 1px;
            margin-top: 40px;
            margin-bottom: 80px;

            @include mq($from: 501px, $until: 767px, $media-type: $mq-type, $mq_debug: null) {
                display: block;
                margin-top: 30px;
                margin-bottom: 30px;
            }
    
            @include mq($until: 500px, $media-type: $mq-type, $mq_debug: null) {
                display: block;
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }  
    }

    &--list {
        display: grid;
        grid-template-columns: repeat(3, 500px);
        grid-gap: 50px;

        @include mq($from: 1350px, $until: 1600px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(3, 400px);
            justify-content: center;
        }

        @include mq($from: 1130px, $until: 1349px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(3, 350px);
            grid-gap: 30px;
            justify-content: center;
        }

        @include mq($from: 1030px, $until: 1129px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(2, 500px);
            grid-gap: 30px;
            justify-content: center;
        }

        @include mq($from: 830px, $until: 1029px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(2, 400px);
            grid-gap: 30px;
            justify-content: center;
        }

        @include mq($from: 768px, $until: 829px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(2, 350px);
            grid-gap: 30px;
            justify-content: center;
        }

        @include mq($from: 501px, $until: 767px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 500px;
            grid-gap: 30px;
            justify-content: center;
        }

        @include mq($until: 500px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 100%;
            grid-gap: 30px;
            justify-content: center;
        }
    }
}

.Offers {
    margin-top: 200px;
    margin-bottom: 160px;

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        margin-top: 100px;
        margin-bottom: 50px;
    }

    &-title {
        color: $white;
        font-weight: 700;
        font-size: 36px;
        text-align: center;
        margin-bottom: 50px;
    }

    &-content {
        margin-bottom: 80px;

        @include mq($until: 530px, $media-type: $mq-type, $mq_debug: null) {
            margin-bottom: 30px;
        }
    }

    &-filter {
        display: flex;
        justify-content: center;

        @include mq($until: 530px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
        }

        &--button {
            color: $white;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            border: solid 1px $white;
            padding: 15px 25px;
            margin: 0 15px;
            transition: border .3s ease, background-color .3s ease;
            cursor: pointer;

            &:hover {
                color: $white;
                background-color: $cc-red;
                border-color: $cc-red;
            }

            @include mq($until: 530px, $media-type: $mq-type, $mq_debug: null) {
                margin-bottom: 20px;
            }
        }
    }

    .Filter-active {
        border-color: $cc-red;
        background-color: $cc-red;
        color: $white;
    }

    &-tiles {
        display: grid;
        grid-template-columns: repeat(3, 500px);
        grid-gap: 80px 50px;
        justify-content: center;
        max-width: 1600px;
        margin: 0 auto;
        height: auto !important;

        @include mq($from: 1350px, $until: 1600px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(3, 400px);
            justify-content: center;
        }

        @include mq($from: 1130px, $until: 1349px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(3, 350px);
            grid-gap: 30px;
            justify-content: center;
        }

        @include mq($from: 1030px, $until: 1129px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(2, 500px);
            grid-gap: 30px;
            justify-content: center;
        }

        @include mq($from: 830px, $until: 1029px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(2, 400px);
            grid-gap: 30px;
            justify-content: center;
        }

        @include mq($from: 768px, $until: 829px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(2, 350px);
            grid-gap: 30px;
            justify-content: center;
        }

        @include mq($from: 501px, $until: 767px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 500px;
            grid-gap: 30px;
            justify-content: center;
        }

        @include mq($until: 500px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 100%;
            grid-gap: 30px;
            justify-content: center;
        }
    }

    &-tile {
        overflow: hidden;
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        transform: translate3d(0, 0, 0) !important;

        &:hover {
            .Offers-tile--hover {
                right: 0;
            }
        }

        &--image {
            position: relative;
            // width: 100%;
            height: 320px;
            margin-bottom: 20px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--hover {
            position: absolute;
            bottom: 0;
            right: -56px;
            width: 56px;
            height: 56px;
            background-color: $cc-red;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .3s ease right;
        }

        &--title {
            color: $white;
            font-family: $font-ProximaNova;
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 15px;

            @include mq($until: 500px, $media-type: $mq-type, $mq_debug: null) {
                padding-right: 20px;
                padding-left: 20px;
            }
        }

        &--info {
            color: $white;
            font-weight: 600;
            font-size: 16px;
            font-family: $font-ProximaNova;
            width: 472px;
            margin-bottom: 7px;

            @include mq($until: 1600px, $media-type: $mq-type, $mq_debug: null) {
                width: auto;
            }

            @include mq($until: 500px, $media-type: $mq-type, $mq_debug: null) {
                padding-right: 20px;
                padding-left: 20px;
            }
        }

        &--price {
            color: $cc-red;
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 0;

            @include mq($until: 500px, $media-type: $mq-type, $mq_debug: null) {
                padding-right: 20px;
                padding-left: 20px;
            }
        }
    }
}