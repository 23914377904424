/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: basic */
/* Colors: Custom */
/* Colors: Theme */
/* Settings: Fonts */
@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Light.woff2") format("woff2"), url("../fonts/ProximaNova-Light.woff") format("woff");
  font-weight: 300; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-LightItalic.woff2") format("woff2"), url("../fonts/ProximaNova-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Regular.woff2") format("woff2"), url("../fonts/ProximaNova-Regular.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-RegularItalic.woff2") format("woff2"), url("../fonts/ProximaNova-RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Semibold.woff2") format("woff2"), url("../fonts/ProximaNova-Semibold.woff") format("woff");
  font-weight: 600; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-SemiboldItalic.woff2") format("woff2"), url("../fonts/ProximaNova-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: 700; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-BoldIt.woff2") format("woff2"), url("../fonts/ProximaNova-BoldIt.woff") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Extrabold.woff2") format("woff2"), url("../fonts/ProximaNova-Extrabold.woff") format("woff");
  font-weight: 800; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova-Black.woff2") format("woff2"), url("../fonts/ProximaNova-Black.woff") format("woff");
  font-style: normal;
  font-weight: 900; }

/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Objects: Toggle */
/* Components: Fields */
/* Components: Modal */
/* Functions: Units */
/* Functions: SVG */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: String */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Caret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Hover */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/* Reset */
*, *::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

iframe {
  border: 0; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  display: block;
  width: 100%; }

option {
  font: inherit; }

textarea {
  resize: vertical; }

button {
  background: transparent;
  border: 0;
  padding: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

abbr[title],
dfn[title] {
  cursor: help; }

u,
ins {
  text-decoration: underline; }

img {
  font-style: italic; }

::selection {
  text-shadow: none; }

[tabindex="-1"]:focus {
  outline: none !important; }

/* HR */
hr {
  margin-top: 24px;
  margin-bottom: 23px;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure > img {
  display: block; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #000000;
  text-decoration: underline;
  text-decoration-thickness: 1px !important;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: #000000; }
  a:hover, a:focus, a:active {
    color: #1f1f1f;
    text-decoration: none; }

/* List */
ol,
ul {
  margin-bottom: 24px;
  padding-left: 24px; }
  ol li,
  ul li {
    margin-bottom: 5px;
    padding-left: 20px;
    list-style-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='21' height='16' viewBox='0 0 21 16'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M7.875,0a1.3,1.3,0,0,1-.928-.391L.385-7.058a1.347,1.347,0,0,1,0-1.885,1.3,1.3,0,0,1,1.856,0L7.826-3.269l10.887-12.29a1.3,1.3,0,0,1,1.853-.1,1.349,1.349,0,0,1,.1,1.883L8.851-.441A1.307,1.307,0,0,1,7.91,0Z' fill='%23da1024' clip-rule='evenodd'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath d='M0,0H21V-16H0Z' fill='%23da1024'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(0 16)' clip-path='url(%23a)'%3E%3Cg clip-path='url(%23b)'%3E%3Cpath d='M-5-21H26V5H-5Z' fill='%23da1024'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 4px;
    margin-bottom: 4px; }

ul {
  list-style-type: disc; }

ul ul {
  list-style-type: circle; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

/* Selection */
::selection {
  color: #ffffff;
  background-color: #da1024; }

/* Heading */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "ProximaNova", sans-serif; }
  .fonts-loaded h1, .fonts-loaded
  h2, .fonts-loaded
  h3, .fonts-loaded
  h4, .fonts-loaded
  h5, .fonts-loaded
  h6 {
    font-family: "ProximaNova", sans-serif; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: normal; }

h1 {
  font-family: "ProximaNova", sans-serif;
  hyphens: auto; }
  h1 p {
    font-family: "ProximaNova", sans-serif !important;
    margin-bottom: 0; }

h2 {
  font-family: "ProximaNova", sans-serif; }

h3 {
  margin-bottom: 24px;
  font-size: 20px; }

h4 {
  margin-bottom: 24px;
  font-size: 18px; }

h5 {
  margin-bottom: 24px;
  font-size: 16px; }

h6 {
  margin-bottom: 24px;
  font-size: 14px; }

.Giga {
  margin-bottom: 24px;
  font-size: 96px;
  line-height: 1.1; }

.Mega {
  margin-bottom: 24px;
  font-size: 72px;
  line-height: 1.1; }

.Kilo {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.2; }

.Milli {
  margin-bottom: 24px;
  font-size: 12px; }

.Micro {
  margin-bottom: 24px;
  font-size: 10px; }

/* Paragraph */
p {
  margin-bottom: 24px;
  font-family: "ProximaNova", sans-serif; }

.Lead {
  font-size: 18px; }

/* Main */
html {
  background-color: #ffffff;
  color: #000000;
  font-family: sans-serif;
  height: 100%;
  scroll-behavior: smooth; }
  html.fonts-loaded {
    font-family: sans-serif; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%;
  position: relative;
  overflow-x: hidden; }

.hide {
  display: none; }

.inactive {
  opacity: .4;
  pointer-events: none; }

/* Alert */
.Alert {
  position: relative;
  margin-bottom: 24px;
  padding: 0.75em 1em 0.75em 1em;
  border: 1px solid;
  border-radius: 2px; }
  .Alert.is-dismissable {
    padding-right: 32px; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 24px; }
  .Alert a:not(.Button--close) {
    font-weight: bold; }
    .Alert a:not(.Button--close):hover {
      text-decoration: underline; }

/* Nav */
@media screen and (max-width: 64em) {
  .Nav {
    margin-top: 50px; } }

.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto; }
  @media screen and (max-width: 64em) {
    .Nav-list {
      flex-direction: column; } }

.Nav-item {
  margin: 0;
  padding: 0;
  list-style: none; }

.Nav-link {
  position: relative;
  user-select: none;
  display: block;
  font-size: 16px;
  color: #ffffff;
  font-weight: 800;
  text-decoration: none;
  text-transform: uppercase;
  margin-left: 65px; }
  @media screen and (min-width: 64.0625em) and (max-width: 75em) {
    .Nav-link {
      margin-left: 30px; } }
  @media screen and (max-width: 64em) {
    .Nav-link {
      margin-left: 0;
      margin-bottom: 25px; } }
  .Nav-link:hover, .Nav-link:focus, .Nav-link:active {
    text-decoration: none;
    color: #da1024; }
  .Nav-link.is-active {
    position: relative;
    color: #da1024; }
    @media screen and (max-width: 64em) {
      .Nav-link.is-active {
        color: #da1024 !important; } }

.Nav-label {
  font-family: "ProximaNova", sans-serif;
  font-weight: 800; }

/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */
.Button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0.5em 0.75em 0.5em 0.75em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal; }
  .Button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .Button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .Button:active, .Button:focus, .Button:hover, .Button.is-active {
    color: inherit;
    text-decoration: none; }
  .Button:disabled, .Button.is-disabled {
    cursor: default;
    opacity: 0.6;
    pointer-events: none; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by1:before {
  padding-bottom: 33.33333%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 24px; }

.Form-label {
  display: inline-block;
  margin-bottom: 6px; }
  .Form-item:not(.is-required) .Form-label::after {
    content: ' - Optional';
    margin-left: 6px; }
    html[lang^="nl"] .Form-item:not(.is-required) .Form-label::after {
      content: ' - Optioneel'; }
    html[lang^="fr"] .Form-item:not(.is-required) .Form-label::after {
      content: ' - Facultatif'; }
  .Form-item.is-error .Form-label {
    color: #f44336; }
  .Form-item.is-success .Form-label {
    color: #4caf50; }

.Form-help {
  margin-top: 6px;
  color: #bdbdbd;
  font-size: 12px; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 12px; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e0e0e0; }

.Form-item.is-error .Form-help--validation {
  color: #f44336; }

.Form-item.is-success .Form-help--validation {
  color: #4caf50; }

.Form-controls {
  position: relative; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 6px;
    margin-bottom: 0;
    padding-right: 24px;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

.Media-offscreen {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

.Media-container {
  background: #000;
  box-sizing: border-box;
  font-family: 'Helvetica', Arial, serif;
  position: relative;
  text-align: left;
  text-indent: 0;
  vertical-align: top; }

.Media-container * {
  box-sizing: border-box; }

/* Hide native play button and control bar from iOS to favor plugin button */
.Media-container video::-webkit-media-controls,
.Media-container video::-webkit-media-controls-panel,
.Media-container video::-webkit-media-controls-panel-container,
.Media-container video::-webkit-media-controls-start-playback-button {
  -webkit-appearance: none;
  display: none !important; }

.Media-fill-container,
.Media-fill-container .Media-container {
  height: 100%;
  width: 100%; }

.Media-fill-container {
  background: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: relative; }

.Media-container:focus {
  outline: none; }

.Media-iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%; }

.Media-embed,
.Media-embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.Media-fullscreen {
  overflow: hidden !important; }

.Media-container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000; }

.Media-container-fullscreen .Media-mediaelement,
.Media-container-fullscreen video {
  height: 100% !important;
  width: 100% !important; }

/* Start: LAYERS */
.Media-background {
  left: 0;
  position: absolute;
  top: 0; }

.Media-mediaelement {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.Media-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1; }

:root .Media-poster-img {
  display: none; }

.Media-poster-img {
  border: 0;
  padding: 0; }

.Media-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0; }

.Media-layer {
  z-index: 1; }

.Media-overlay-play {
  cursor: pointer; }

.Media-overlay-button {
  background: url("../img/media/controls.svg") no-repeat;
  background-position: 0 -39px;
  height: 80px;
  width: 80px; }

.Media-overlay:hover > .Media-overlay-button {
  background-position: -80px -39px; }

.Media-overlay-loading {
  height: 80px;
  width: 80px; }

.Media-overlay-loading-bg-img {
  animation: Media-loading-spinner 1s linear infinite;
  background: transparent url("../img/media/controls.svg") -160px -40px no-repeat;
  display: block;
  height: 80px;
  width: 80px;
  z-index: 1; }

@keyframes Media-loading-spinner {
  100% {
    transform: rotate(360deg); } }

/* End: LAYERS */
/* Start: CONTROL BAR */
.Media-controls {
  bottom: 0;
  display: flex;
  height: 40px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  width: 100%;
  z-index: 3; }

.Media-controls:not([style*='display: none']) {
  background: rgba(255, 0, 0, 0.7);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.35)); }

.Media-button,
.Media-time,
.Media-time-rail {
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  margin: 0;
  width: 32px; }

.Media-button > button {
  background: transparent url("../img/media/controls.svg");
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px; }

/* :focus for accessibility */
.Media-button > button:focus {
  outline: dotted 1px #999; }

.Media-container-keyboard-inactive a,
.Media-container-keyboard-inactive a:focus,
.Media-container-keyboard-inactive button,
.Media-container-keyboard-inactive button:focus,
.Media-container-keyboard-inactive [role=slider],
.Media-container-keyboard-inactive [role=slider]:focus {
  outline: 0; }

/* End: CONTROL BAR */
/* Start: Time (Current / Duration) */
.Media-time {
  box-sizing: content-box;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  overflow: hidden;
  padding: 16px 6px 0;
  text-align: center;
  width: auto; }

/* End: Time (Current / Duration) */
/* Start: Play/Pause/Stop */
.Media-play > button {
  background-position: 0 0; }

.Media-pause > button {
  background-position: -20px 0; }

.Media-replay > button {
  background-position: -160px 0; }

/* End: Play/Pause/Stop */
/* Start: Progress Bar */
.Media-time-rail {
  direction: ltr;
  flex-grow: 1;
  height: 40px;
  margin: 0 10px;
  padding-top: 10px;
  position: relative; }

.Media-time-total,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-current,
.Media-time-float,
.Media-time-hovered,
.Media-time-float-current,
.Media-time-float-corner,
.Media-time-marker {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 10px;
  position: absolute; }

.Media-time-total {
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 0 0;
  width: 100%; }

.Media-time-buffering {
  animation: buffering-stripes 2s linear infinite;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
  width: 100%; }

@keyframes buffering-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.Media-time-loaded {
  background: rgba(255, 255, 255, 0.3); }

.Media-time-current,
.Media-time-handle-content {
  background: rgba(255, 255, 255, 0.9); }

.Media-time-hovered {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10; }

.Media-time-hovered.negative {
  background: rgba(0, 0, 0, 0.2); }

.Media-time-current,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-hovered {
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0.15s ease-in all;
  width: 100%; }

.Media-time-buffering {
  transform: scaleX(1); }

.Media-time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1); }

.Media-time-hovered.no-hover {
  transform: scaleX(0) !important; }

.Media-time-handle,
.Media-time-handle-content {
  border: 4px solid transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  transform: translateX(0);
  z-index: 11; }

.Media-time-handle-content {
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 10px;
  left: -7px;
  top: -4px;
  transform: scale(0);
  width: 10px; }

.Media-time-rail:hover .Media-time-handle-content,
.Media-time-rail .Media-time-handle-content:focus,
.Media-time-rail .Media-time-handle-content:active {
  transform: scale(1); }

.Media-time-float {
  background: #eee;
  border: solid 1px #333;
  bottom: 100%;
  color: #111;
  display: none;
  height: 17px;
  margin-bottom: 9px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 36px; }

.Media-time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px; }

.Media-time-float-corner {
  border: solid 5px #eee;
  border-color: #eee transparent transparent;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0; }

.Media-long-video .Media-time-float {
  margin-left: -23px;
  width: 64px; }

.Media-long-video .Media-time-float-current {
  width: 60px; }

.Media-broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%; }

/* End: Progress Bar */
/* Start: Fullscreen */
.Media-fullscreen-button > button {
  background-position: -80px 0; }

.Media-unfullscreen > button {
  background-position: -100px 0; }

/* End: Fullscreen */
/* Start: Mute/Volume */
.Media-mute > button {
  background-position: -60px 0; }

.Media-unmute > button {
  background-position: -40px 0; }

.Media-volume-button {
  position: relative; }

.Media-volume-button > .Media-volume-slider {
  -webkit-backface-visibility: hidden;
  background: rgba(50, 50, 50, 0.7);
  border-radius: 0;
  bottom: 100%;
  display: none;
  height: 115px;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 25px;
  z-index: 1; }

.Media-volume-button:hover {
  border-radius: 0 0 4px 4px; }

.Media-volume-total {
  background: rgba(255, 255, 255, 0.5);
  height: 100px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 8px;
  transform: translateX(-50%);
  width: 2px; }

.Media-volume-current {
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%; }

.Media-volume-handle {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1px;
  cursor: ns-resize;
  height: 6px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 16px; }

.Media-horizontal-volume-slider {
  display: block;
  height: 36px;
  position: relative;
  vertical-align: middle;
  width: 56px; }

.Media-horizontal-volume-total {
  background: rgba(50, 50, 50, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 8px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  width: 50px; }

.Media-horizontal-volume-current {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.Media-horizontal-volume-handle {
  display: none; }

/* End: Mute/Volume */
/* Start: Track (Captions and Chapters) */
.Media-captions-button,
.Media-chapters-button {
  position: relative; }

.Media-captions-button > button {
  background-position: -140px 0; }

.Media-chapters-button > button {
  background-position: -180px 0; }

.Media-captions-button > .Media-captions-selector,
.Media-chapters-button > .Media-chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: solid 1px transparent;
  border-radius: 0;
  bottom: 100%;
  margin-right: -43px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 50%;
  visibility: visible;
  width: 86px; }

.Media-chapters-button > .Media-chapters-selector {
  margin-right: -55px;
  width: 110px; }

.Media-captions-selector-list,
.Media-chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item,
.Media-chapters-selector-list-item {
  color: #fff;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item:hover,
.Media-chapters-selector-list-item:hover {
  background-color: #c8c8c8 !important;
  background-color: rgba(255, 255, 255, 0.4) !important; }

.Media-captions-selector-input,
.Media-chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute; }

.Media-captions-selector-label,
.Media-chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 10px 0;
  width: 100%; }

.Media-captions-selected,
.Media-chapters-selected {
  color: #21f8f8; }

.Media-captions-translations {
  font-size: 10px;
  margin: 0 0 5px; }

.Media-captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center; }

.Media-captions-layer a {
  color: #fff;
  text-decoration: underline; }

.Media-captions-layer[lang=ar] {
  font-size: 20px;
  font-weight: normal; }

.Media-captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%; }

.Media-captions-position-hover {
  bottom: 35px; }

.Media-captions-text,
.Media-captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap; }

.Media-container.Media-hide-cues video::-webkit-media-text-track-container {
  display: none; }

/* End: Track (Captions and Chapters) */
/* Start: Error */
.Media-overlay-error {
  position: relative; }

.Media-overlay-error > img {
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: -1; }

.Media-cannotplay,
.Media-cannotplay a {
  color: #fff;
  font-size: 0.8em; }

.Media-cannotplay {
  position: relative; }

.Media-cannotplay p,
.Media-cannotplay a {
  display: inline-block;
  padding: 0 15px;
  width: 100%; }

/* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--withExtraSmallGutter {
  margin-left: -6px; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-left: 6px; }

.Grid--withSmallGutter {
  margin-left: -12px; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-left: 12px; }

.Grid--withGutter {
  margin-left: -24px; }
  .Grid--withGutter > .Grid-cell {
    padding-left: 24px; }

.Grid--withLargeGutter {
  margin-left: -48px; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-left: 48px; }

.Grid--withExtraLargeGutter {
  margin-left: -96px; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-left: 96px; }

.Grid--withExtraExtraLargeGutter {
  margin-left: -96px; }
  .Grid--withExtraExtraLargeGutter > .Grid-cell {
    padding-left: 96px; }

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0; }
  .Icon svg {
    fill: currentColor;
    height: 1em;
    width: 1em; }
  .Icon.Icon--baseline svg {
    top: 0.125em;
    position: relative; }

/* Structure */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1064px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%; }
  .Container--xxl {
    max-width: 1960px;
    padding-right: 100px;
    padding-left: 100px; }
  .Container--xl {
    max-width: 1480px; }
  .Container--lg {
    max-width: 1320px; }
  .Container--md {
    max-width: 1064px; }
  .Container--sm {
    max-width: 1000px; }
  .Container--xs {
    max-width: 640px; }
  @media screen and (min-width: 111.25em) {
    .Container {
      max-width: 1104px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xxl {
        max-width: 2000px;
        padding-right: 360px;
        padding-left: 360px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1104px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }
  @media screen and (min-width: 20em) and (max-width: 37.5625em) {
    .Container {
      max-width: 1104px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xxl {
        max-width: 2000px;
        padding-right: 20px;
        padding-left: 20px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1104px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer";
  background-color: #050505; }

.Header {
  position: relative;
  z-index: 3;
  grid-area: header;
  height: 100px; }

.Footer {
  position: relative;
  z-index: 1;
  grid-area: footer; }

.Main {
  position: relative;
  z-index: 2;
  grid-area: main; }
  .Main > .Container {
    padding-top: 24px;
    padding-bottom: 24px; }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        grid-gap: 48px; }
        .Main > .Container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .Main > .Container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .Main > .Container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.Side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .Side {
      margin-top: 24px; } }
  @media screen and (min-width: 48em) {
    .Side--left {
      grid-area: left; }
    .Side--right {
      grid-area: right; } }

.Content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    vertical-align: middle;
    padding: 4px 8px 4px 8px; }
  .Table thead {
    background-color: #f5f5f5;
    border-bottom: 2px solid #e0e0e0; }
  .Table tbody tr + tr {
    border-top: 1px solid #e0e0e0; }
  .Table tfoot {
    background-color: #f5f5f5;
    border-top: 2px solid #e0e0e0; }
  @media screen and (max-width: 47.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tbody tr {
        border: 1px solid #e0e0e0;
        border-bottom-width: 0; }
      .Table.is-responsive tfoot {
        border: 1px solid #e0e0e0;
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: inline-block;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-table-label]::before {
          content: attr(data-table-label);
          display: block;
          margin: -12px -12px 12px;
          padding: 12px;
          background-color: #fafafa;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 47.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

.Toggle {
  height: 24px;
  width: 30px;
  position: relative;
  cursor: pointer;
  transform: rotate(0deg);
  z-index: 999999999; }
  .Toggle-item {
    height: 2px;
    width: 100%;
    position: absolute;
    transition-property: top, transform, opacity;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    background-color: #000000;
    border-radius: 0px;
    display: block;
    transform: rotate(0deg); }
    .Toggle-item:nth-child(1) {
      top: 0; }
      .show-nav .Toggle-item:nth-child(1) {
        top: 11px;
        background-color: #000000;
        transform: rotate(135deg); }
    .Toggle-item:nth-child(2) {
      top: 11px; }
      .show-nav .Toggle-item:nth-child(2) {
        opacity: 0; }
    .Toggle-item:nth-child(3) {
      top: 22px; }
      .show-nav .Toggle-item:nth-child(3) {
        top: 11px;
        background-color: #000000;
        transform: rotate(-135deg); }

.Header {
  background-color: transparent;
  width: 100%;
  position: absolute;
  top: 0;
  padding: 40px 160px;
  border-top: 5px solid #e42432; }
  @media screen and (min-width: 64em) and (max-width: 100em) {
    .Header {
      padding: 40px 50px; } }
  @media screen and (max-width: 63.9375em) {
    .Header {
      padding: 30px 20px; } }

.HeaderColor {
  background-color: #1f1f1f;
  transition: background-color 1.5s ease; }

/* Alert */
.Alert--success {
  color: #659667;
  background-color: #eef6ef;
  border-color: #dfeddf; }
  .Alert--success a {
    color: #4c714d; }

.Alert--info {
  color: #5690bf;
  background-color: #ebf4fb;
  border-color: #d7e9f7; }
  .Alert--info a {
    color: #396d96; }

.Alert--warning {
  color: #bf8c40;
  background-color: #fcf4e9;
  border-color: #f9ead3; }
  .Alert--warning a {
    color: #8f6930; }

.Alert--danger {
  color: #c56c66;
  background-color: #fceeed;
  border-color: #f8dbd9; }
  .Alert--danger a {
    color: #a2453e; }

/* Button */
.Button--primary {
  background-color: #da1024;
  border: solid 1px #da1024;
  border-radius: 50px;
  color: #ffffff;
  font-size: 16px;
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  transition: background-color .1s ease, border .1s ease,  color .1s ease;
  width: max-content;
  padding: 15px 20px;
  text-transform: uppercase;
  letter-spacing: 1px; }
  .Button--primary:not(.Button--outline):active, .Button--primary:not(.Button--outline):focus, .Button--primary:not(.Button--outline):hover, .Button--primary:not(.Button--outline).is-active {
    background-color: transparent;
    border: solid 1px #da1024;
    color: #da1024; }
  .Button--primary.Button--outline {
    background-color: transparent;
    border: solid 1px #ffffff;
    color: #ffffff;
    font-size: 14px;
    font-family: "ProximaNova", sans-serif;
    font-weight: 600;
    transition: border .1s ease, background-color .1s ease , color .1s ease, fill .1s ease;
    border-radius: 0; }
    .Button--primary.Button--outline:active, .Button--primary.Button--outline:focus, .Button--primary.Button--outline:hover, .Button--primary.Button--outline.is-active {
      transition: border .1s ease, background-color .1s ease, color .1s ease;
      border: solid 1px #ffffff;
      background-color: #ffffff;
      color: #050505; }

.Button--secondary {
  background-color: #da1024;
  border: solid 1px #da1024;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  transition: background-color .1s ease, border .1s ease,  color .1s ease;
  width: max-content;
  padding: 15px 20px; }
  .Button--secondary:not(.Button--outline):active, .Button--secondary:not(.Button--outline):focus, .Button--secondary:not(.Button--outline):hover, .Button--secondary:not(.Button--outline).is-active {
    background-color: transparent;
    border: solid 1px #da1024;
    color: #ffffff; }
  .Button--secondary.Button--outline {
    background-color: transparent;
    border: solid 1px #ffffff;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    transition: border .1s ease, background-color .1s ease , color .1s ease, fill .1s ease;
    border-radius: 0; }
    .Button--secondary.Button--outline:active, .Button--secondary.Button--outline:focus, .Button--secondary.Button--outline:hover, .Button--secondary.Button--outline.is-active {
      transition: border .1s ease, background-color .1s ease, color .1s ease;
      border: solid 1px #ffffff;
      background-color: #ffffff;
      color: #ffffff; }

.Button--black {
  background-color: transparent;
  border: solid 2px #000000;
  border-radius: 50px;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  padding: 15px 20px;
  transition: background-color .1s ease, border .1s ease,  color .1s ease;
  width: max-content; }
  .Button--black svg path {
    transition: stroke .1s ease, fill .1s ease;
    stroke: #000000;
    fill: #000000; }
  .Button--black:active, .Button--black:focus, .Button--black:hover {
    background-color: #000000;
    border: solid 2px #000000;
    color: #ffffff; }
    .Button--black:active svg path, .Button--black:focus svg path, .Button--black:hover svg path {
      stroke: #ffffff;
      fill: #ffffff; }

.Button-red {
  position: relative;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  padding-left: 81px;
  padding-right: 25px;
  z-index: 50;
  width: max-content;
  height: 58px; }
  @media screen and (max-width: 25em) {
    .Button-red {
      font-size: 16px; } }
  .Button-red::before {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='13' viewBox='0 0 20 13'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M18.1-7.012l-5.093-5.265.7-.723L20-6.5,13.712,0l-.7-.723L18.1-5.988H0V-7.012Z' fill='%23fff'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath d='M0,0H20V-13H0Z' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(0 13)' clip-path='url(%23a)'%3E%3Cg clip-path='url(%23b)'%3E%3Cpath d='M-5-18H25V5H-5Z' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    position: absolute;
    left: 0;
    z-index: -1;
    width: 56px;
    height: 56px;
    padding: 14px 18px;
    background-color: #da1024;
    transition: width .3s ease; }
  .Button-red:hover {
    color: #ffffff; }
    .Button-red:hover:before {
      width: 100%; }

/* Cookie */
.CookieConsent {
  background-color: #696969;
  color: #ffffff;
  padding: 15px 0;
  transition-property: transform, opacity, visibility;
  transition-duration: .2s;
  transition-delay: 0s, .2s, .2s;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  opacity: .9; }
  .CookieConsent p {
    margin-bottom: 0; }

.CookieConsent:not(.is-active) {
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden; }

.CookieConsent > .Container {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media screen and (max-width: 34.375em) {
    .CookieConsent > .Container {
      flex-direction: column; } }

.cookie-text {
  font-size: 15px; }

.CookieConsent a {
  font-weight: bold;
  margin-left: 10px;
  color: #ffffff;
  text-decoration: underline; }
  .CookieConsent a, .CookieConsent a:active, .CookieConsent a:focus, .CookieConsent a:hover {
    color: #ffffff; }

.Close {
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  margin-left: 10px;
  line-height: 1; }
  .Close.Button--outline {
    color: #ffffff;
    border-radius: 50px; }
    .Close.Button--outline:hover {
      color: #ffffff; }
  @media screen and (max-width: 34.375em) {
    .Close {
      margin-top: 20px;
      margin-left: 0; } }

/* Outdated Browser */
.Outdated {
  background-color: #f44336;
  color: #ffffff;
  font-size: 12px;
  padding: 12px 24px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1500; }
  .Outdated-title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px; }
  .Outdated-text {
    margin-bottom: 0;
    line-height: 1; }
  .Outdated-button {
    border: 2px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-weight: 700;
    line-height: 1;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    width: 230px;
    /*need for IE*/
    position: absolute;
    top: 12px;
    right: 24px; }
    .Outdated-button:hover {
      background-color: #ffffff;
      color: #f44336; }
  * html .Outdated {
    position: absolute; }

/* Navigation */
.Navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 64em) {
    .Navigation {
      flex-direction: row-reverse; } }
  .Navigation-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 160px;
    padding-right: 160px;
    width: 100%;
    margin-top: 30px; }
    @media screen and (min-width: 64.0625em) and (max-width: 101.25em) {
      .Navigation-container {
        padding-left: 50px;
        padding-right: 50px; } }
    @media screen and (max-width: 64em) {
      .Navigation-container {
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
        justify-content: center; } }
  .Navigation-button {
    display: none; }
  .Navigation #menu-button {
    position: relative;
    z-index: 5;
    cursor: pointer;
    user-select: none;
    height: 27px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out; }
    @media screen and (min-width: 64.0625em) {
      .Navigation #menu-button {
        display: none !important; } }
    .Navigation #menu-button span {
      display: block;
      position: relative;
      height: 2px;
      width: 30px;
      border-radius: 2px;
      opacity: 1;
      background-color: #da1024;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: .3s ease-in-out; }
      .Navigation #menu-button span:nth-child(1) {
        top: 0; }
      .Navigation #menu-button span:nth-child(2) {
        top: 7px; }
      .Navigation #menu-button span:nth-child(3) {
        top: 14px; }
  .Navigation-button:checked ~ #menu-button span {
    background-color: #ffffff; }
    .Navigation-button:checked ~ #menu-button span:nth-child(1) {
      top: 11px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg); }
    .Navigation-button:checked ~ #menu-button span:nth-child(2) {
      opacity: 0; }
    .Navigation-button:checked ~ #menu-button span:nth-child(3) {
      top: 8px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg); }
  .Navigation-button:checked ~ .Navigation-body {
    transform: translateX(0);
    transition: all .3s ease-in-out;
    z-index: -1; }
  .Navigation-button:checked ~ .Logo2 svg path {
    transition: .5s fill ease;
    fill: #ffffff; }
  .Navigation-body {
    visibility: visible;
    z-index: 2;
    display: flex; }
    @media screen and (max-width: 64em) {
      .Navigation-body {
        position: fixed;
        top: 0;
        right: 0;
        min-height: 100vh;
        height: -webkit-fill-available;
        width: 100vw;
        background-color: #1f1f1f;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        transform: translateX(100vw);
        transition: all .3s ease-in-out;
        padding-top: 70px;
        padding-bottom: 50px;
        flex-direction: column; } }
  .Navigation-socials {
    display: flex;
    align-items: center;
    margin-left: 70px; }
    .Navigation-socials a {
      margin-right: 20px; }
      .Navigation-socials a:last-child {
        margin-right: 0; }
    .Navigation-socials svg {
      height: 24px;
      width: auto; }
      .Navigation-socials svg path {
        transition: fill .3s ease; }
        .Navigation-socials svg path:hover {
          fill: #da1024; }
    @media screen and (min-width: 64.0625em) and (max-width: 75em) {
      .Navigation-socials {
        margin-left: 30px; } }
    @media screen and (max-width: 64em) {
      .Navigation-socials {
        margin-left: 0;
        justify-content: center; } }

/* Types */
/* Lang */
.Nav--lang {
  position: relative;
  z-index: 1;
  margin-left: 15px; }
  .Nav--lang:not(:hover) .Nav-list {
    opacity: 0;
    visibility: hidden; }
  .Nav--lang .Nav-toggle {
    cursor: default;
    align-items: center;
    display: flex;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase; }
    .Nav--lang .Nav-toggle svg path {
      fill: #ffffff; }
  .Nav--lang .Nav-list {
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    top: 100%;
    right: 0;
    color: #000000;
    background-color: #ffffff;
    flex-direction: column;
    padding: 5px 10px; }
  .Nav--lang .Nav-item {
    height: auto;
    padding: 10px 0;
    margin-bottom: 0; }
  .Nav--lang .Nav-link {
    color: #000000;
    transition: color .2s ease, text-decoration .2s ease;
    margin-left: 0;
    font-size: 16px;
    font-weight: 400; }
    .Nav--lang .Nav-link::after {
      display: none; }
    .Nav--lang .Nav-link:hover, .Nav--lang .Nav-link:active, .Nav--lang .Nav-link:focus {
      color: #da1024; }
    .Nav--lang .Nav-link.active {
      color: #da1024;
      font-weight: 700; }

.Nav--footer .Nav-list {
  margin-left: -12px; }

.Nav--footer .Nav-item {
  padding-left: 12px; }

/* Logo */
.Logo {
  position: relative;
  z-index: 10; }
  @media screen and (min-width: 25.0625em) and (max-width: 81.875em) {
    .Logo svg {
      width: 250px;
      height: auto; } }
  @media screen and (max-width: 25em) {
    .Logo svg {
      width: 180px;
      height: auto; } }

/* Fields */
/* Fields: field */
.Form-field--input,
.Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  background-color: #ffffff;
  border-radius: 0;
  border: 1px solid #bdbdbd;
  color: #424242;
  vertical-align: middle;
  width: 100%; }
  @media screen and (min-width: 37.5em) {
    .Form-field--input,
    .Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
      max-width: 360px; } }
  .Form-item.is-error .Form-field--input,
  .Form-item.is-error .Form-field--textarea, .Form-item.is-error .Form-field--select {
    border-color: #f44336; }
  .Form-item.is-success .Form-field--input,
  .Form-item.is-success .Form-field--textarea, .Form-item.is-success .Form-field--select {
    border-color: #4caf50; }

.Form-field--input,
.Form-field--textarea, .Form-field--select {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }
  .Form-field--input:focus,
  .Form-field--textarea:focus, .Form-field--select:focus {
    border-color: #757575;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px #64b5f6;
    outline: 0;
    z-index: 1; }
    .Form-item.is-error .Form-field--input:focus,
    .Form-item.is-error .Form-field--textarea:focus, .Form-item.is-error .Form-field--select:focus {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(244, 67, 54, 0.6); }
    .Form-item.is-success .Form-field--input:focus,
    .Form-item.is-success .Form-field--textarea:focus, .Form-item.is-success .Form-field--select:focus {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(76, 175, 80, 0.6); }

.Form-field {
  font-family: inherit;
  position: relative; }

.Form-indicator {
  user-select: none; }

.Form-field--xs {
  max-width: 72px !important; }

.Form-field--sm {
  max-width: 120px !important; }

.Form-field--md {
  max-width: 240px !important; }

.Form-field--lg {
  max-width: 360px !important; }

.Form-field--xl {
  max-width: 480px !important; }

.Form-field--full {
  max-width: none !important; }

/* Fields: check */
.Form-field--radio,
.Form-field--checkbox {
  cursor: pointer;
  display: flex;
  user-select: none; }
  .Form-field--radio:not(:first-of-type),
  .Form-field--checkbox:not(:first-of-type) {
    margin-top: 3px; }
  .Form-field--radio input[type="radio"],
  .Form-field--radio input[type="checkbox"],
  .Form-field--checkbox input[type="radio"],
  .Form-field--checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--radio input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
      background-color: #757575;
      border-color: #757575; }
    .Form-field--radio input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:focus ~ .Form-indicator {
      border-color: #757575;
      box-shadow: 0 0 2px 1px #64b5f6; }
  .Form-field--radio .Form-indicator,
  .Form-field--checkbox .Form-indicator {
    background-color: #ffffff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.5625em 0.5625em;
    border: 1px solid #bdbdbd;
    flex-shrink: 0;
    height: 1em;
    margin-right: 12px;
    width: 1em;
    position: relative;
    top: 0.25em; }

.Form-field--radio > .Form-indicator {
  border-radius: 100%; }

.Form-field--radio input[type="radio"]:checked ~ .Form-indicator::after {
  background-color: #ffffff;
  border-radius: 100%;
  content: '';
  height: 0.3em;
  width: 0.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.Form-field--checkbox > .Form-indicator {
  border-radius: 0; }

.Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23fff'/%3E%3C/svg%3E"); }

/* Fields: file */
.Form-field--file {
  cursor: pointer;
  display: block; }
  .Form-field--file input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--file input[type='file']:focus ~ .Form-indicator {
      border-color: #757575;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px #64b5f6; }
      .Form-item.is-error .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(244, 67, 54, 0.6); }
      .Form-item.is-success .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(76, 175, 80, 0.6); }
  .Form-field--file .Form-indicator {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffffff;
    border-radius: 0;
    border: 1px solid #bdbdbd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    color: #424242;
    display: block;
    line-height: normal;
    overflow: hidden;
    padding: 0.25em 0.5em 0.25em 0.5em;
    position: relative;
    white-space: nowrap;
    width: 100%; }
    .Form-field--file .Form-indicator::before {
      content: attr(data-file-text);
      display: block;
      padding-right: 100px; }
    .Form-field--file .Form-indicator::after {
      background-color: #eeeeee;
      border-left: 1px solid #bdbdbd;
      color: #757575;
      content: attr(data-file-button);
      padding: 0.25em 0.5em 0.25em 0.5em;
      text-align: center;
      width: 100px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0; }
  @media screen and (min-width: 37.5em) {
    .Form-field--file {
      max-width: 360px; } }

/* Fields: input */
.Form-field--input {
  padding: 0.25em 0.5em 0.25em 0.5em; }

.Form-field--textarea {
  padding: 0.125em 0.5em 0.125em 0.5em; }

/* Fields: select */
select {
  cursor: pointer;
  width: 100% !important; }
  @media screen and (min-width: 37.5em) {
    select {
      max-width: 360px; } }

.Form-field--select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20292.36%20402%22%3E%3Cpath%20d%3D%22M18.27%2C164.45H274.09a18.17%2C18.17%2C0%2C0%2C0%2C12.85-31.12L159%2C5.42a17.93%2C17.93%2C0%2C0%2C0-25.7%2C0L5.42%2C133.33a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7A17.57%2C17.57%2C0%2C0%2C0%2C18.27%2C164.45Z%22%2F%3E%3Cpath%20d%3D%22M274.09%2C237.55H18.27A17.57%2C17.57%2C0%2C0%2C0%2C5.42%2C243a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7l127.91%2C127.9a17.92%2C17.92%2C0%2C0%2C0%2C25.7%2C0L286.93%2C268.66a18.27%2C18.27%2C0%2C0%2C0-12.85-31.12Z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-size: 12px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: normal;
  overflow: hidden;
  padding: 0.25em 2.5em 0.25em 0.5em; }

.Form-field--select[multiple] {
  height: auto; }
  .Form-field--select[multiple] option {
    padding: 0.125em 0.5em 0.125em 0.5em; }
    .Form-field--select[multiple] option + option {
      border-top: 1px solid #bdbdbd; }

/* Slider */
/* Slider: core */
.Slider {
  position: relative;
  z-index: 1;
  /* Fix of Webkit flickering */
  overflow: hidden; }
  .Slider--no-flexbox .Slider-item {
    float: left; }
  .Slider--vertical > .Slider-items {
    flex-direction: column; }
  .Slider-items {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    transition-property: transform; }
  .Slider--android .Slider-item, .Slider-items {
    transform: translate3d(0px, 0, 0); }
  .Slider--multirow > .Slider-items {
    flex-wrap: wrap; }
  .Slider--free-mode > .Slider-items {
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .Slider-item {
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    transition-property: transform; }
    .Slider-item.is-blank {
      visibility: hidden; }
  .Slider--autoheight,
  .Slider--autoheight .Slider-item {
    height: auto; }
  .Slider--autoheight .Slider-items {
    align-items: flex-start;
    transition-property: transform, height; }
  .Slider--3d {
    perspective: 1200px; }
    .Slider--3d .Slider-items,
    .Slider--3d .Slider-item,
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom,
    .Slider--3d .swiper-cube-shadow {
      transform-style: preserve-3d; }
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10; }
    .Slider--3d .Slider-item-shadow-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-top {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .Slider--wp8-horizontal,
  .Slider--wp8-horizontal > .Slider-items {
    touch-action: pan-y; }
  .Slider--wp8-vertical,
  .Slider--wp8-vertical > .Slider-items {
    touch-action: pan-x; }

/* Slider: a11y */
.Slider .Slider-notification {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  pointer-events: none;
  opacity: 0; }

/* Slider: fade */
.Slider--fade.Slider--free-mode .Slider-item {
  transition-timing-function: ease-out; }

.Slider--fade .Slider-item {
  pointer-events: none;
  transition-property: opacity; }
  .Slider--fade .Slider-item .Slider-item {
    pointer-events: none; }

.Slider--fade .is-active,
.Slider--fade .is-active .is-active {
  pointer-events: auto; }

/* Slider: coverflow */
.Slider--coverflow .Slider-items {
  -ms-perspective: 1200px;
  /* Windows 8 IE 10 fix */ }

/* Slider: cube */
.Slider--cube {
  overflow: visible; }
  .Slider--cube .Slider-item {
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    pointer-events: none;
    transform-origin: 0 0;
    visibility: hidden;
    z-index: 1; }
    .Slider--cube .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--cube.Slider--rtl .Slider-item {
    transform-origin: 100% 0; }
  .Slider--cube .is-active,
  .Slider--cube .is-active .is-active {
    pointer-events: auto; }
  .Slider--cube .is-active,
  .Slider--cube .is-next,
  .Slider--cube .is-prev,
  .Slider--cube .is-next + .Slider-item {
    pointer-events: auto;
    visibility: visible; }
  .Slider--cube .swiper-slide-shadow-top,
  .Slider--cube .swiper-slide-shadow-bottom,
  .Slider--cube .swiper-slide-shadow-left,
  .Slider--cube .swiper-slide-shadow-right {
    backface-visibility: hidden;
    z-index: 0; }
  .Slider--cube .swiper-cube-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #000000;
    filter: blur(50px);
    opacity: 0.6; }

/* Slider: flip */
.Slider--flip {
  overflow: visible; }
  .Slider--flip .Slider-item {
    backface-visibility: hidden;
    pointer-events: none;
    z-index: 1; }
    .Slider--flip .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--flip .is-active,
  .Slider--flip .is-active .is-active {
    pointer-events: auto; }
  .Slider--flip .Slider-item--shadow-top,
  .Slider--flip .Slider-item--shadow-bottom,
  .Slider--flip .Slider-item--shadow-left,
  .Slider--flip .Slider-item--shadow-right {
    backface-visibility: hidden;
    z-index: 0; }

/* Slider: lazy */
.Slider-preloader {
  height: 42px;
  width: 42px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
  margin-top: -21px;
  margin-left: -21px; }
  .Slider-preloader:after {
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: block; }

.Slider-preloader--white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/* Slider: navigation */
.Slider-button {
  height: 48px;
  width: 48px;
  position: absolute;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat; }
  .Slider-button.is-disabled {
    cursor: auto;
    opacity: 0.35; }
  .Slider-button.is-locked {
    display: none; }

.Slider-button--next:focus, .Slider-button--prev:focus {
  outline: none; }

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20px'%20height%3D'13px'%20viewBox%3D'0%200%2020%2013'%20version%3D'1.1'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cg%20id%3D'DESIGN'%20stroke%3D'none'%20stroke-width%3D'1'%20fill%3D'none'%20fill-rule%3D'evenodd'%3E%3Cg%20id%3D'01_home'%20transform%3D'translate(-172.000000%2C%20-2046.000000)'%20fill%3D'%23000000'%20fill-rule%3D'nonzero'%3E%3Cg%20id%3D'diensten'%20transform%3D'translate(158.000000%2C%201211.000000)'%3E%3Cg%20id%3D'pijl-copy'%20transform%3D'translate(24.000000%2C%20842.000000)%20scale(-1%2C%201)%20translate(-24.000000%2C%20-842.000000)%20translate(0.000000%2C%20818.000000)'%3E%3Cpolygon%20id%3D'long-arrow-right-copy-2'%20points%3D'32.105%2022.988%2027.012%2017.723%2027.712%2017%2034%2023.5%2027.712%2030%2027.012%2029.277%2032.105%2024.012%2014%2024.012%2014%2022.988'%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  right: auto;
  left: 0; }

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20px'%20height%3D'13px'%20viewBox%3D'0%200%2020%2013'%20version%3D'1.1'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cg%20id%3D'DESIGN'%20stroke%3D'none'%20stroke-width%3D'1'%20fill%3D'none'%20fill-rule%3D'evenodd'%3E%3Cg%20id%3D'02_diensten'%20transform%3D'translate(-922.000000%2C%20-591.000000)'%20fill%3D'%23000000'%20fill-rule%3D'nonzero'%3E%3Cg%20id%3D'werkbaar-werk'%20transform%3D'translate(741.000000%2C%20573.000000)'%3E%3Cpolygon%20id%3D'long-arrow-right-copy'%20points%3D'199.13648%2023.988%20194.04348%2018.723%20194.74348%2018%20201.03148%2024.5%20194.74348%2031%20194.04348%2030.277%20199.13648%2025.012%20181.03148%2025.012%20181.03148%2023.988'%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  right: 0;
  left: auto; }

.Referenties .Slider-button {
  height: 48px;
  width: 48px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  margin: auto 0;
  cursor: pointer;
  background-color: none;
  background-position: center;
  background-repeat: no-repeat; }
  .Referenties .Slider-button.is-disabled {
    cursor: auto;
    opacity: 0.35; }
  .Referenties .Slider-button.is-locked {
    display: none; }

.Referenties .Slider-button--next:focus, .Referenties .Slider-button--prev:focus {
  outline: none; }

.Referenties .Slider-button--prev,
.Referenties .Slider--rtl .Slider-button--next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20px'%20height%3D'13px'%20viewBox%3D'0%200%2020%2013'%20version%3D'1.1'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cg%20id%3D'DESIGN'%20stroke%3D'none'%20stroke-width%3D'1'%20fill%3D'none'%20fill-rule%3D'evenodd'%3E%3Cg%20id%3D'01_home'%20transform%3D'translate(-172.000000%2C%20-2046.000000)'%20fill%3D'%23000000'%20fill-rule%3D'nonzero'%3E%3Cg%20id%3D'diensten'%20transform%3D'translate(158.000000%2C%201211.000000)'%3E%3Cg%20id%3D'pijl-copy'%20transform%3D'translate(24.000000%2C%20842.000000)%20scale(-1%2C%201)%20translate(-24.000000%2C%20-842.000000)%20translate(0.000000%2C%20818.000000)'%3E%3Cpolygon%20id%3D'long-arrow-right-copy-2'%20points%3D'32.105%2022.988%2027.012%2017.723%2027.712%2017%2034%2023.5%2027.712%2030%2027.012%2029.277%2032.105%2024.012%2014%2024.012%2014%2022.988'%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  right: auto;
  left: 0px; }

.Referenties .Slider-button--next,
.Referenties .Slider--rtl .Slider-button--prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20px'%20height%3D'13px'%20viewBox%3D'0%200%2020%2013'%20version%3D'1.1'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cg%20id%3D'DESIGN'%20stroke%3D'none'%20stroke-width%3D'1'%20fill%3D'none'%20fill-rule%3D'evenodd'%3E%3Cg%20id%3D'02_diensten'%20transform%3D'translate(-922.000000%2C%20-591.000000)'%20fill%3D'%23000000'%20fill-rule%3D'nonzero'%3E%3Cg%20id%3D'werkbaar-werk'%20transform%3D'translate(741.000000%2C%20573.000000)'%3E%3Cpolygon%20id%3D'long-arrow-right-copy'%20points%3D'199.13648%2023.988%20194.04348%2018.723%20194.74348%2018%20201.03148%2024.5%20194.74348%2031%20194.04348%2030.277%20199.13648%2025.012%20181.03148%2025.012%20181.03148%2023.988'%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  right: 0px;
  left: auto; }

/* Slider: pagination */
.Slider-pagination {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  position: absolute;
  z-index: 10;
  text-align: center;
  transform: translate3d(0, 0, 0); }
  .Slider-pagination.is-hidden {
    opacity: 0; }
  .Slider-pagination.is-locked {
    display: none; }

.Slider-pagination--fraction,
.Slider-pagination--custom,
.Slider--horizontal > .Slider-pagination--bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

.Slider-pagination--bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transform: scale(0.33);
    position: relative; }
  .Slider-pagination--bullets-dynamic .is-active {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-main {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-prev {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-prev-prev {
    transform: scale(0.33); }
  .Slider-pagination--bullets-dynamic .is-active-next {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-next-next {
    transform: scale(0.33); }

.Slider-pagination-item {
  height: 8px;
  width: 8px;
  background: #000000;
  border-radius: 100%;
  display: inline-block;
  opacity: 0.2; }
  button.Slider-pagination-item {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none; }
  .is-clickable .Slider-pagination-item {
    cursor: pointer; }
  .Slider-pagination-item.is-active {
    opacity: 1;
    background-color: white;
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2); }

.Slider--vertical > .Slider-pagination--bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0); }
  .Slider--vertical > .Slider-pagination--bullets .Slider-pagination-item {
    display: block;
    margin-right: 6px;
    margin-left: 6px; }
  .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }
    .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
      display: inline-block;
      transition-property: transform, top;
      transition-duration: 200ms; }

.Slider--horizontal > .Slider-pagination--bullets .Slider-pagination-item {
  margin: 0 4px; }

.Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; }
  .Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transition-property: transform, left;
    transition-duration: 200ms; }

.Slider--horizontal.Slider--rtl > .Slider-pagination--bullets-dynamic .Slider-pagination-item {
  transition-property: transform, right;
  transition-duration: 200ms; }

.Slider-pagination--progressbar {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .Slider-pagination--progressbar .Slider-pagination-fill {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    transform: scale(0);
    transform-origin: left top; }
  .Slider--rtl .Slider-pagination--progressbar .Slider-pagination-fill {
    transform-origin: right top; }
  .Slider--horizontal > .Slider-pagination--progressbar,
  .Slider--vertical > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 4px;
    width: 100%;
    left: 0;
    top: 0; }
  .Slider--vertical > .Slider-pagination--progressbar,
  .Slider--horizontal > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 100%;
    width: 4px;
    left: 0;
    top: 0; }

/* Slider: scrollbar */
.Slider-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none; }
  .Slider--horizontal > .Slider-scrollbar {
    height: 5px;
    width: 98%;
    position: absolute;
    bottom: 3px;
    left: 1%;
    z-index: 50; }
  .Slider--vertical > .Slider-scrollbar {
    height: 98%;
    width: 5px;
    position: absolute;
    top: 1%;
    right: 3px;
    z-index: 50; }
  .Slider-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px; }
  .Slider-scrollbar-cursor-drag {
    cursor: move; }
  .Slider-scrollbar .is-locked {
    display: none; }

/* Slider: thumbs */
/* Slider: zoom */
.Slider--zoom {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .Slider--zoom > img,
  .Slider--zoom > svg,
  .Slider--zoom > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }
  .Slider--zoom .is-zoomed {
    cursor: move; }

/* General */
html {
  background-color: #050505; }

body:not(.is-tabbing) span:focus,
body:not(.is-tabbing) button:focus,
body:not(.is-tabbing) input:focus,
body:not(.is-tabbing) select:focus,
body:not(.is-tabbing) textarea:focus {
  outline: none; }

.Main {
  position: relative; }

.BackButton a {
  font-family: "ProximaNova", sans-serif;
  font-weight: 600;
  color: #696969;
  margin-bottom: 20px; }
  .BackButton a:hover {
    color: #ffffff; }

@media screen and (min-width: 48.0625em) and (max-width: 100em) {
  .BackButton {
    margin-left: 50px; } }

@media screen and (max-width: 48em) {
  .BackButton {
    margin-left: 20px; } }

.split-child {
  overflow: hidden; }

/* Blocks */
.Footer {
  display: flex;
  justify-content: space-between;
  padding: 70px 160px;
  border-top: #696969 solid 1px; }
  @media screen and (min-width: 48.0625em) and (max-width: 75em) {
    .Footer {
      padding: 50px 50px; } }
  @media screen and (min-width: 51.9375em) and (max-width: 54.375em) {
    .Footer {
      padding: 50px 20px; } }
  @media screen and (max-width: 51.875em) {
    .Footer {
      padding: 50px 20px;
      flex-direction: column; } }
  .Footer-container {
    display: grid;
    grid-template-columns: 480px 330px 160px; }
    @media screen and (min-width: 78.75em) and (max-width: 93.75em) {
      .Footer-container {
        grid-template-columns: 350px 260px 160px; } }
    @media screen and (min-width: 51.9375em) and (max-width: 78.6875em) {
      .Footer-container {
        grid-template-columns: 250px 260px 160px; } }
    @media screen and (max-width: 51.875em) {
      .Footer-container {
        grid-template-columns: 100%; } }
  .Footer-title {
    color: #da1024;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 15px; }
  .Footer-logo svg {
    height: auto;
    width: 285px; }
    @media screen and (max-width: 80em) {
      .Footer-logo svg {
        width: 200px; } }
  @media screen and (max-width: 51.875em) {
    .Footer-logo {
      margin-bottom: 50px; } }
  .Footer-data {
    font-family: "ProximaNova", sans-serif;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 0; }
    .Footer-data:last-child {
      margin-bottom: 0; }
  .Footer-contact {
    display: flex;
    flex-direction: column;
    margin-top: 37px; }
    .Footer-contact a {
      font-family: "ProximaNova", sans-serif; }
    @media screen and (max-width: 51.875em) {
      .Footer-contact {
        margin-bottom: 30px; } }
  .Footer-link {
    font-family: "ProximaNova", sans-serif;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 0; }
    .Footer-link:nth-last-child {
      margin-bottom: 0; }
    .Footer-link:hover {
      color: #696969; }
  .Footer .Nav {
    margin-top: 0; }
  .Footer .Nav-link {
    margin-left: 0;
    font-weight: 600;
    margin-bottom: 0; }
  .Footer .Nav-label {
    font-weight: 600; }
  .Footer-nav .Nav-list {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }
  .Footer-nav .Nav-item {
    padding: 0;
    margin-bottom: 0; }
  .Footer-nav .Nav-link {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px; }
    .Footer-nav .Nav-link.is-active {
      color: #ffffff; }
      @media screen and (max-width: 64em) {
        .Footer-nav .Nav-link.is-active {
          color: #ffffff !important; } }
    .Footer-nav .Nav-link:hover {
      color: #696969; }
  .Footer-socials {
    display: flex;
    align-items: center; }
    .Footer-socials p {
      margin-bottom: 0;
      color: #ffffff;
      font-weight: 700;
      letter-spacing: 1px;
      margin-right: 10px; }
    .Footer-socials a {
      margin-right: 10px; }
      .Footer-socials a:last-child {
        margin-right: 0; }
    .Footer-socials svg {
      height: 15px;
      width: auto; }
      .Footer-socials svg path {
        transition: fill .3s ease; }
        .Footer-socials svg path:hover {
          fill: #da1024; }
  .Footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media screen and (max-width: 51.875em) {
      .Footer-wrapper {
        margin-top: 30px; } }
  .Footer-privacy {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    @media screen and (max-width: 51.875em) {
      .Footer-privacy {
        align-items: flex-start; } }
    .Footer-privacy .nav--permission .Nav-list {
      justify-content: flex-start;
      flex-direction: row; }
    .Footer-privacy .nav--permission .Nav-item {
      padding: 0;
      margin-bottom: 0;
      font-weight: 300; }
    .Footer-privacy .nav--permission .Nav-link {
      font-family: "ProximaNova", sans-serif;
      text-transform: capitalize;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 24px;
      color: #696969; }
      .Footer-privacy .nav--permission .Nav-link:hover {
        color: #ffffff; }
    .Footer-privacy .nav--permission .Nav-label {
      font-family: "ProximaNova", sans-serif;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 24px;
      color: #696969;
      transition: color .3s ease; }
      .Footer-privacy .nav--permission .Nav-label:hover {
        color: #ffffff; }
    .Footer-privacy .Moqo {
      font-family: "ProximaNova", sans-serif;
      font-weight: 300;
      color: #696969;
      font-size: 14px;
      letter-spacing: 1px; }
      .Footer-privacy .Moqo:hover {
        color: #ffffff; }

/* Print */
@media print {
  *,
  *::before,
  *::after,
  *::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    letter-spacing: 0;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]::after {
    content: " (" attr(href) ")"; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #9e9e9e;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  /**
     * General
     */
  html {
    font-family: sans-serif;
    font-size: 12pt; }
  .Container {
    max-width: none; }
  h1,
  h2,
  h3 {
    margin-bottom: 12pt;
    text-transform: none; }
  h1 {
    font-size: 24pt; }
  h2 {
    font-size: 18pt; }
  h3 {
    font-size: 14pt; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.Error {
  margin-top: 160px;
  margin-bottom: 160px;
  font-family: "ProximaNova", sans-serif;
  font-size: 16px;
  color: #1f1f1f;
  text-align: center; }

.Thanks {
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  margin-bottom: 160px;
  width: 960px;
  text-align: center; }
  @media screen and (max-width: 64em) {
    .Thanks {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 200px; } }
  .Thanks-title {
    margin-bottom: 30px;
    color: #ffffff; }
  .Thanks-text {
    color: #ffffff;
    font-size: 20px;
    margin-bottom: 50px; }
    .Thanks-text a {
      color: #da1024; }
      .Thanks-text a:hover, .Thanks-text a:active, .Thanks-text a:focus {
        color: #ffffff; }
    .Thanks-text strong {
      color: #ffffff; }

.Privacy {
  margin: 120px auto;
  width: 960px; }
  @media screen and (max-width: 64em) {
    .Privacy {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 100px;
      margin-bottom: 50px; } }
  .Privacy-title {
    margin-bottom: 30px;
    color: #ffffff; }
  .Privacy-subtitle {
    color: #ffffff; }
  .Privacy-text {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 50px; }
    .Privacy-text a {
      color: #da1024; }
      .Privacy-text a:hover, .Privacy-text a:active, .Privacy-text a:focus {
        color: #696969; }
    .Privacy-text strong {
      color: #ffffff; }
    .Privacy-text ul {
      list-style-type: disc !important; }
    .Privacy-text li {
      color: #ffffff;
      list-style-image: unset; }
  .Privacy-back {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-weight: 700; }
    .Privacy-back a {
      margin-bottom: 0; }
    .Privacy-back svg {
      margin-right: 10px;
      transition: transform .3s ease; }
    .Privacy-back:hover svg, .Privacy-back:focus svg, .Privacy-back:active svg {
      animation: leftRight 1s ease infinite;
      -webkit-animation: leftRight 1s ease infinite; }

@keyframes leftRight {
  0%, 100% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px); }
  50% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px); } }

.Banner {
  position: relative;
  width: 100vw;
  height: 100vh; }
  @media screen and (max-width: 48em) {
    .Banner {
      height: 100vh; } }
  .Banner-images {
    width: 100%;
    height: 100%; }
    @media screen and (max-width: 48em) {
      .Banner-images {
        height: 100%; } }
  .Banner-image {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.3);
    background-blend-mode: multiply;
    width: 100vw;
    height: 100vh; }
    @media screen and (max-width: 48em) {
      .Banner-image {
        height: 100vh; } }
    .Banner-image--info {
      position: absolute;
      bottom: 70px;
      left: 320px;
      color: #ffffff;
      font-size: 18px;
      line-height: 32px;
      font-weight: 600; }
      @media screen and (min-width: 90em) and (max-width: 100em) {
        .Banner-image--info {
          left: 160px; } }
      @media screen and (min-width: 48.0625em) and (max-width: 89.9375em) {
        .Banner-image--info {
          left: 50px; } }
      @media screen and (max-width: 48em) {
        .Banner-image--info {
          left: 20px;
          bottom: 150px;
          height: 100vh; } }
      .Banner-image--info p {
        margin-bottom: 0; }
  .Banner-title {
    position: absolute;
    top: 40%;
    left: 320px;
    color: #ffffff;
    font-size: 56px;
    font-weight: 700;
    line-height: 65px;
    margin-bottom: 0;
    width: 640px;
    overflow: hidden;
    opacity: 0;
    transition: opacity .1s ease; }
    .Banner-title.show {
      opacity: 1 !important; }
    @media screen and (min-width: 90em) and (max-width: 100em) {
      .Banner-title {
        left: 260px; } }
    @media screen and (min-width: 48.0625em) and (max-width: 89.9375em) {
      .Banner-title {
        left: 150px;
        font-size: 36px;
        line-height: 45px; } }
    @media screen and (min-width: 22.5625em) and (max-width: 48em) {
      .Banner-title {
        left: 20px;
        font-size: 28px;
        line-height: 35px;
        width: auto; } }
    @media screen and (max-width: 22.5em) {
      .Banner-title {
        left: 20px;
        font-size: 25px;
        line-height: 30px;
        hyphens: auto;
        width: 100%; } }
    .Banner-title p {
      margin-bottom: 0; }
  .Banner-number {
    position: absolute;
    top: 40%;
    left: 160px;
    color: #da1024;
    font-weight: 700;
    font-size: 24px;
    font-family: "ProximaNova", sans-serif; }
    @media screen and (min-width: 90em) and (max-width: 100em) {
      .Banner-number {
        left: 160px; } }
    @media screen and (min-width: 48.0625em) and (max-width: 89.9375em) {
      .Banner-number {
        left: 50px; } }
    @media screen and (max-width: 48em) {
      .Banner-number {
        left: 20px;
        top: 32%; } }
  .Banner-cta {
    position: absolute;
    bottom: 70px;
    right: 150px;
    height: 50px; }
    @media screen and (min-width: 48.0625em) and (max-width: 89.9375em) {
      .Banner-cta {
        right: 50px; } }
    @media screen and (max-width: 48em) {
      .Banner-cta {
        right: unset;
        left: 20px;
        bottom: 30px; } }

.CallToActionBlock {
  display: grid;
  grid-template-columns: repeat(2, 640px);
  max-width: 1280px;
  margin: 160px auto; }
  @media screen and (min-width: 68.125em) and (max-width: 80.625em) {
    .CallToActionBlock {
      grid-template-columns: repeat(2, 540px);
      justify-content: center; } }
  @media screen and (min-width: 55.0625em) and (max-width: 68.0625em) {
    .CallToActionBlock {
      grid-template-columns: repeat(2, 440px);
      justify-content: center; } }
  @media screen and (min-width: 40.0625em) and (max-width: 55em) {
    .CallToActionBlock {
      grid-template-columns: 640px;
      justify-content: center; } }
  @media screen and (max-width: 40.0625em) {
    .CallToActionBlock {
      grid-template-columns: 100%;
      justify-content: center;
      margin: 50px auto; } }
  .CallToActionBlock-image {
    width: 100%;
    height: 720px; }
    .CallToActionBlock-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media screen and (min-width: 55.0625em) and (max-width: 68.0625em) {
      .CallToActionBlock-image {
        height: auto;
        min-height: 500px; } }
    @media screen and (max-width: 55em) {
      .CallToActionBlock-image {
        height: 320px; } }
  .CallToActionBlock-content {
    height: 100%;
    background-color: #1f1f1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 160px;
    padding-right: 130px; }
    @media screen and (min-width: 68.125em) and (max-width: 80.625em) {
      .CallToActionBlock-content {
        padding-left: 100px;
        padding-right: 100px; } }
    @media screen and (min-width: 55.0625em) and (max-width: 68.0625em) {
      .CallToActionBlock-content {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 30px;
        padding-bottom: 30px; } }
    @media screen and (max-width: 55em) {
      .CallToActionBlock-content {
        padding: 50px 20px; } }
  .CallToActionBlock-title {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 42px; }
    .CallToActionBlock-title p {
      margin-bottom: 0; }
    @media screen and (max-width: 48em) {
      .CallToActionBlock-title {
        font-size: 28px; } }
  .CallToActionBlock-text {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500; }
    .CallToActionBlock-text p:last-child {
      margin-bottom: 0; }
  .CallToActionBlock-button--cta {
    margin-bottom: 37px; }
  .CallToActionBlock-line {
    background-color: #da1024;
    width: 80px;
    height: 1px;
    margin-top: 50px;
    margin-bottom: 50px; }
    @media screen and (max-width: 48em) {
      .CallToActionBlock-line {
        margin-top: 30px;
        margin-bottom: 30px; } }
  .CallToActionBlock .Button-red {
    margin-top: 15px; }
    .CallToActionBlock .Button-red:first-child {
      margin-top: 0; }

.Parallax {
  width: 100vw;
  height: 840px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; }
  @media screen and (min-width: 48.0625em) and (max-width: 93.75em) {
    .Parallax {
      height: 500px; } }
  @media screen and (max-width: 48em) {
    .Parallax {
      height: 320px;
      background-attachment: unset; } }

.InfoBlock {
  display: grid;
  grid-template-columns: 840px 562px;
  grid-gap: 80px;
  max-width: 1480px;
  margin: 160px auto 0; }
  @media screen and (min-width: 90.625em) and (max-width: 93.75em) {
    .InfoBlock {
      grid-gap: 30px; } }
  @media screen and (min-width: 76.9375em) and (max-width: 90.5625em) {
    .InfoBlock {
      grid-template-columns: 640px 562px;
      grid-gap: 30px;
      justify-content: center; } }
  @media screen and (min-width: 48.0625em) and (max-width: 76.875em) {
    .InfoBlock {
      grid-template-columns: 100%;
      grid-gap: 30px; } }
  @media screen and (max-width: 48em) {
    .InfoBlock {
      grid-template-columns: 100%;
      grid-gap: 30px;
      margin: 50px auto 0; } }
  .InfoBlock-left {
    height: max-content; }
    @media screen and (min-width: 48.0625em) and (max-width: 76.875em) {
      .InfoBlock-left {
        display: flex; } }
    @media screen and (max-width: 48em) {
      .InfoBlock-left {
        display: block; } }
    .InfoBlock-left--wrapper {
      display: grid;
      grid-template-columns: 240px 602px; }
      @media screen and (min-width: 76.9375em) and (max-width: 90.5625em) {
        .InfoBlock-left--wrapper {
          grid-template-columns: 1fr 500px; } }
      @media screen and (min-width: 64em) and (max-width: 76.875em) {
        .InfoBlock-left--wrapper {
          grid-template-columns: 620px; } }
      @media screen and (min-width: 48.0625em) and (max-width: 63.9375em) {
        .InfoBlock-left--wrapper {
          grid-template-columns: 420px; } }
      @media screen and (max-width: 48em) {
        .InfoBlock-left--wrapper {
          grid-template-columns: 100%; } }
  .InfoBlock-right {
    height: max-content;
    margin-top: 125px; }
    @media screen and (min-width: 76.9375em) and (max-width: 90.5625em) {
      .InfoBlock-right {
        margin-top: 50px; } }
    @media screen and (min-width: 48.0625em) and (max-width: 76.875em) {
      .InfoBlock-right {
        margin-top: 50px;
        display: flex; } }
    @media screen and (max-width: 48em) {
      .InfoBlock-right {
        margin-top: 0px;
        display: flex;
        flex-direction: column-reverse; } }
    @media screen and (min-width: 48.0625em) and (max-width: 76.875em) {
      .InfoBlock-right a {
        margin-left: 50px;
        margin-top: 14px;
        margin-bottom: 50px; } }
    @media screen and (max-width: 48em) {
      .InfoBlock-right a {
        margin-left: 20px;
        margin-top: 30px;
        margin-bottom: 50px; } }
  .InfoBlock-subtitle {
    position: relative; }
    .InfoBlock-subtitle h1 {
      position: absolute;
      right: 75px;
      color: #1f1f1f;
      font-size: 56px;
      font-weight: 700;
      margin: 0;
      white-space: nowrap;
      transform: translateY(85px) rotate(270deg);
      transform-origin: right; }
      @media screen and (min-width: 76.9375em) and (max-width: 90.5625em) {
        .InfoBlock-subtitle h1 {
          font-size: 40px; } }
      @media screen and (min-width: 64em) and (max-width: 76.875em) {
        .InfoBlock-subtitle h1 {
          font-size: 40px;
          transform: unset;
          position: relative;
          right: unset;
          margin-left: 50px; } }
      @media screen and (max-width: 63.9375em) {
        .InfoBlock-subtitle h1 {
          display: none; } }
  .InfoBlock-title {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 42px; }
    .InfoBlock-title p {
      margin-bottom: 0; }
    @media screen and (max-width: 48em) {
      .InfoBlock-title {
        font-size: 28px; } }
  .InfoBlock-text {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 37px; }
    .InfoBlock-text ul {
      margin-top: 200px; }
      @media screen and (min-width: 76.9375em) and (max-width: 90.5625em) {
        .InfoBlock-text ul {
          margin-top: 50px; } }
      @media screen and (min-width: 75.0625em) and (max-width: 76.875em) {
        .InfoBlock-text ul {
          margin-top: 50px;
          margin-left: 50px; } }
      @media screen and (max-width: 75em) {
        .InfoBlock-text ul {
          margin-top: 0px;
          margin-left: 20px;
          margin-right: 20px; } }
      .InfoBlock-text ul li {
        margin-bottom: 20px; }
        .InfoBlock-text ul li:nth-last-child {
          margin-bottom: 0; }
  .InfoBlock-line {
    background-color: #da1024;
    width: 80px;
    height: 1px;
    margin-top: 50px;
    margin-bottom: 50px; }
    @media screen and (max-width: 48em) {
      .InfoBlock-line {
        margin-top: 30px;
        margin-bottom: 30px; } }
  .InfoBlock-infoLeft {
    background-color: #1f1f1f;
    padding: 125px 90px 90px 110px;
    margin-bottom: 80px; }
    @media screen and (min-width: 76.9375em) and (max-width: 90.5625em) {
      .InfoBlock-infoLeft {
        padding: 50px 50px; } }
    @media screen and (min-width: 48.0625em) and (max-width: 76.875em) {
      .InfoBlock-infoLeft {
        padding: 50px 50px;
        margin-bottom: 0; } }
    @media screen and (max-width: 48em) {
      .InfoBlock-infoLeft {
        padding: 50px 20px;
        margin-bottom: 0; } }
  .InfoBlock-image--left {
    width: 100%;
    height: 593px; }
    .InfoBlock-image--left img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media screen and (min-width: 48.0625em) and (max-width: 63.9375em) {
      .InfoBlock-image--left {
        height: auto; } }
    @media screen and (max-width: 48em) {
      .InfoBlock-image--left {
        height: 320px; } }
  .InfoBlock-image--right {
    width: 100%;
    height: 805px; }
    .InfoBlock-image--right img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media screen and (min-width: 48.0625em) and (max-width: 76.875em) {
      .InfoBlock-image--right {
        height: auto;
        width: 50%; } }
    @media screen and (max-width: 48em) {
      .InfoBlock-image--right {
        height: 320px; } }
    .InfoBlock-image--right--bottom {
      margin-bottom: 80px; }
      @media screen and (max-width: 48em) {
        .InfoBlock-image--right--bottom {
          margin-bottom: 0; } }

.AboutPage .InfoBlock, .RacingTeamPage .InfoBlock {
  margin-bottom: 160px; }
  @media screen and (max-width: 48em) {
    .AboutPage .InfoBlock, .RacingTeamPage .InfoBlock {
      margin-bottom: 50px; } }

.RacingTeamPage .InfoBlock-subtitle h1 {
  transform: translateY(-45px) rotate(270deg); }
  @media screen and (min-width: 76.9375em) and (max-width: 90.5625em) {
    .RacingTeamPage .InfoBlock-subtitle h1 {
      transform: translateY(-30px) rotate(270deg); } }
  @media screen and (max-width: 76.875em) {
    .RacingTeamPage .InfoBlock-subtitle h1 {
      transform: unset; } }

.Contact {
  max-width: 1600px;
  margin: 200px auto 0; }
  @media screen and (min-width: 48.0625em) and (max-width: 76.8125em) {
    .Contact {
      margin: 150px 50px 0; } }
  @media screen and (max-width: 48em) {
    .Contact {
      margin: 100px 20px 0; } }
  .Contact-container {
    display: grid;
    grid-template-columns: 640px 800px;
    grid-gap: 160px;
    justify-content: center; }
    @media screen and (min-width: 76.875em) and (max-width: 100em) {
      .Contact-container {
        grid-template-columns: 640px 500px;
        grid-gap: 80px; } }
    @media screen and (max-width: 76.8125em) {
      .Contact-container {
        grid-template-columns: 1fr;
        grid-gap: 50px; } }
  @media screen and (min-width: 51.9375em) and (max-width: 76.8125em) {
    .Contact-responsive {
      display: flex;
      justify-content: space-between; } }
  @media screen and (max-width: 51.875em) {
    .Contact-responsive {
      flex-direction: column; } }
  .Contact-wrapper {
    position: relative;
    z-index: 9999;
    pointer-events: none; }
  .Contact-title {
    color: #ffffff;
    text-align: center;
    margin-bottom: 120px; }
    @media screen and (max-width: 76.8125em) {
      .Contact-title {
        margin-bottom: 50px; } }
  .Contact-subtitle {
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
    text-transform: uppercase; }
  .Contact-data {
    color: #ffffff;
    margin-bottom: 30px; }
    .Contact-data p {
      margin-bottom: 0; }
  .Contact-link {
    color: #ffffff; }
    .Contact-link:hover {
      color: #696969; }
  .Contact-info {
    background-color: #1f1f1f;
    width: 100%;
    padding: 80px;
    pointer-events: all; }
    @media screen and (max-width: 37.4375em) {
      .Contact-info {
        padding: 30px; } }
    .Contact-info p {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600; }
      .Contact-info p strong {
        text-transform: uppercase;
        color: #da1024;
        font-size: 16px;
        margin-bottom: 0;
        letter-spacing: 1px;
        font-weight: 600; }
      .Contact-info p em {
        color: #696969;
        font-style: normal;
        font-size: 16px; }
        .Contact-info p em a {
          color: #696969;
          transition: color .3s ease;
          text-decoration: none; }
          .Contact-info p em a:hover {
            color: #ffffff; }
  .Contact-form {
    color: #ffffff; }
    .Contact-form .Form-label {
      display: none; }
    .Contact-form .Form-field, .Contact-form .Form--input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      padding: 8px 0;
      background-color: transparent !important;
      border-radius: 0;
      border-top: hidden;
      border-left: hidden;
      border-right: hidden;
      border-bottom: 2px solid #696969;
      font-size: 14px;
      font-weight: 600;
      color: #da1024;
      margin-bottom: 24px; }
      .Contact-form .Form-field:active, .Contact-form .Form-field:focus, .Contact-form .Form--input:active, .Contact-form .Form--input:focus {
        border-bottom: 2px solid #da1024; }
        .Contact-form .Form-field:active::placeholder, .Contact-form .Form-field:focus::placeholder, .Contact-form .Form--input:active::placeholder, .Contact-form .Form--input:focus::placeholder {
          color: #ffffff;
          font-size: 14px;
          font-weight: 600;
          text-align: right; }
      .Contact-form .Form-field::placeholder, .Contact-form .Form--input::placeholder {
        color: #ffffff;
        font-size: 14px;
        font-weight: 600; }
    .Contact-form .Form-item--action {
      display: flex;
      justify-content: flex-end; }

.GoogleMaps {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: -250px;
  margin-bottom: 160px;
  z-index: 1; }
  @media screen and (max-width: 76.8125em) {
    .GoogleMaps {
      margin-top: 50px;
      margin-bottom: 50px; } }
  .GoogleMaps .Maps {
    width: 1120px;
    height: 480px; }
    @media screen and (max-width: 76.8125em) {
      .GoogleMaps .Maps {
        width: 100vw; } }

.Home-offers {
  max-width: 1600px;
  margin: 160px auto; }
  @media screen and (min-width: 84.375em) and (max-width: 100em) {
    .Home-offers {
      width: 1300px; } }
  @media screen and (min-width: 70.625em) and (max-width: 84.3125em) {
    .Home-offers {
      width: 1110px; } }
  @media screen and (min-width: 64.375em) and (max-width: 70.5625em) {
    .Home-offers {
      width: 1030px; } }
  @media screen and (min-width: 51.875em) and (max-width: 64.3125em) {
    .Home-offers {
      width: 830px; } }
  @media screen and (min-width: 48em) and (max-width: 51.8125em) {
    .Home-offers {
      width: 730px; } }
  @media screen and (max-width: 48em) {
    .Home-offers {
      margin: 50px auto; } }
  .Home-offers--content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (min-width: 31.3125em) and (max-width: 47.9375em) {
      .Home-offers--content {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 120px;
        margin-bottom: 50px; } }
    @media screen and (max-width: 31.25em) {
      .Home-offers--content {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
        margin-bottom: 50px; } }
    .Home-offers--content h2 {
      color: #ffffff;
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 0; }
    .Home-offers--content a {
      height: 58px; }
  .Home-offers--line {
    background-color: #da1024;
    width: 80px;
    height: 1px;
    margin-top: 40px;
    margin-bottom: 80px; }
    @media screen and (min-width: 31.3125em) and (max-width: 47.9375em) {
      .Home-offers--line {
        display: none; } }
    @media screen and (max-width: 31.25em) {
      .Home-offers--line {
        display: none; } }
    .Home-offers--line__mobile {
      display: none;
      background-color: #da1024;
      width: 80px;
      height: 1px;
      margin-top: 40px;
      margin-bottom: 80px; }
      @media screen and (min-width: 31.3125em) and (max-width: 47.9375em) {
        .Home-offers--line__mobile {
          display: block;
          margin-top: 30px;
          margin-bottom: 30px; } }
      @media screen and (max-width: 31.25em) {
        .Home-offers--line__mobile {
          display: block;
          margin-top: 30px;
          margin-bottom: 30px; } }
  .Home-offers--list {
    display: grid;
    grid-template-columns: repeat(3, 500px);
    grid-gap: 50px; }
    @media screen and (min-width: 84.375em) and (max-width: 100em) {
      .Home-offers--list {
        grid-template-columns: repeat(3, 400px);
        justify-content: center; } }
    @media screen and (min-width: 70.625em) and (max-width: 84.3125em) {
      .Home-offers--list {
        grid-template-columns: repeat(3, 350px);
        grid-gap: 30px;
        justify-content: center; } }
    @media screen and (min-width: 64.375em) and (max-width: 70.5625em) {
      .Home-offers--list {
        grid-template-columns: repeat(2, 500px);
        grid-gap: 30px;
        justify-content: center; } }
    @media screen and (min-width: 51.875em) and (max-width: 64.3125em) {
      .Home-offers--list {
        grid-template-columns: repeat(2, 400px);
        grid-gap: 30px;
        justify-content: center; } }
    @media screen and (min-width: 48em) and (max-width: 51.8125em) {
      .Home-offers--list {
        grid-template-columns: repeat(2, 350px);
        grid-gap: 30px;
        justify-content: center; } }
    @media screen and (min-width: 31.3125em) and (max-width: 47.9375em) {
      .Home-offers--list {
        grid-template-columns: 500px;
        grid-gap: 30px;
        justify-content: center; } }
    @media screen and (max-width: 31.25em) {
      .Home-offers--list {
        grid-template-columns: 100%;
        grid-gap: 30px;
        justify-content: center; } }

.Offers {
  margin-top: 200px;
  margin-bottom: 160px; }
  @media screen and (max-width: 48em) {
    .Offers {
      margin-top: 100px;
      margin-bottom: 50px; } }
  .Offers-title {
    color: #ffffff;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    margin-bottom: 50px; }
  .Offers-content {
    margin-bottom: 80px; }
    @media screen and (max-width: 33.125em) {
      .Offers-content {
        margin-bottom: 30px; } }
  .Offers-filter {
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 33.125em) {
      .Offers-filter {
        flex-direction: column; } }
    .Offers-filter--button {
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      border: solid 1px #ffffff;
      padding: 15px 25px;
      margin: 0 15px;
      transition: border .3s ease, background-color .3s ease;
      cursor: pointer; }
      .Offers-filter--button:hover {
        color: #ffffff;
        background-color: #da1024;
        border-color: #da1024; }
      @media screen and (max-width: 33.125em) {
        .Offers-filter--button {
          margin-bottom: 20px; } }
  .Offers .Filter-active {
    border-color: #da1024;
    background-color: #da1024;
    color: #ffffff; }
  .Offers-tiles {
    display: grid;
    grid-template-columns: repeat(3, 500px);
    grid-gap: 80px 50px;
    justify-content: center;
    max-width: 1600px;
    margin: 0 auto;
    height: auto !important; }
    @media screen and (min-width: 84.375em) and (max-width: 100em) {
      .Offers-tiles {
        grid-template-columns: repeat(3, 400px);
        justify-content: center; } }
    @media screen and (min-width: 70.625em) and (max-width: 84.3125em) {
      .Offers-tiles {
        grid-template-columns: repeat(3, 350px);
        grid-gap: 30px;
        justify-content: center; } }
    @media screen and (min-width: 64.375em) and (max-width: 70.5625em) {
      .Offers-tiles {
        grid-template-columns: repeat(2, 500px);
        grid-gap: 30px;
        justify-content: center; } }
    @media screen and (min-width: 51.875em) and (max-width: 64.3125em) {
      .Offers-tiles {
        grid-template-columns: repeat(2, 400px);
        grid-gap: 30px;
        justify-content: center; } }
    @media screen and (min-width: 48em) and (max-width: 51.8125em) {
      .Offers-tiles {
        grid-template-columns: repeat(2, 350px);
        grid-gap: 30px;
        justify-content: center; } }
    @media screen and (min-width: 31.3125em) and (max-width: 47.9375em) {
      .Offers-tiles {
        grid-template-columns: 500px;
        grid-gap: 30px;
        justify-content: center; } }
    @media screen and (max-width: 31.25em) {
      .Offers-tiles {
        grid-template-columns: 100%;
        grid-gap: 30px;
        justify-content: center; } }
  .Offers-tile {
    overflow: hidden;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    transform: translate3d(0, 0, 0) !important; }
    .Offers-tile:hover .Offers-tile--hover {
      right: 0; }
    .Offers-tile--image {
      position: relative;
      height: 320px;
      margin-bottom: 20px; }
      .Offers-tile--image img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .Offers-tile--hover {
      position: absolute;
      bottom: 0;
      right: -56px;
      width: 56px;
      height: 56px;
      background-color: #da1024;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s ease right; }
    .Offers-tile--title {
      color: #ffffff;
      font-family: "ProximaNova", sans-serif;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 15px; }
      @media screen and (max-width: 31.25em) {
        .Offers-tile--title {
          padding-right: 20px;
          padding-left: 20px; } }
    .Offers-tile--info {
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      font-family: "ProximaNova", sans-serif;
      width: 472px;
      margin-bottom: 7px; }
      @media screen and (max-width: 100em) {
        .Offers-tile--info {
          width: auto; } }
      @media screen and (max-width: 31.25em) {
        .Offers-tile--info {
          padding-right: 20px;
          padding-left: 20px; } }
    .Offers-tile--price {
      color: #da1024;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 0; }
      @media screen and (max-width: 31.25em) {
        .Offers-tile--price {
          padding-right: 20px;
          padding-left: 20px; } }

.OfferContainer {
  max-width: 1600px;
  margin: 200px auto 0; }
  @media screen and (max-width: 48em) {
    .OfferContainer {
      margin-top: 100px; } }

.OfferDetail {
  position: relative;
  display: grid;
  grid-template-columns: 960px 625px;
  grid-template-areas: 'content info';
  grid-gap: 16px;
  justify-content: center;
  margin-bottom: 120px;
  height: max-content; }
  @media screen and (min-width: 81.3125em) and (max-width: 100em) {
    .OfferDetail {
      grid-template-columns: 660px 625px; } }
  @media screen and (min-width: 75.0625em) and (max-width: 81.25em) {
    .OfferDetail {
      grid-template-columns: 560px 625px; } }
  @media screen and (max-width: 75em) {
    .OfferDetail {
      grid-template-columns: 100%;
      grid-template-areas: 'thumbnail' 'info' 'content'; } }
  @media screen and (max-width: 48em) {
    .OfferDetail {
      margin-bottom: 50px; } }
  .OfferDetail-images {
    grid-area: content; }
    @media screen and (min-width: 48.0625em) and (max-width: 75em) {
      .OfferDetail-images {
        margin-left: 20px; } }
    @media screen and (min-width: 48.0625em) and (max-width: 75em) {
      .OfferDetail-images .Images-container {
        display: flex; } }
    @media screen and (max-width: 48em) {
      .OfferDetail-images .Images-container {
        display: flex;
        flex-direction: column;
        align-items: center; } }
    .OfferDetail-images--thumbnail {
      position: relative;
      width: 100%;
      height: 672px;
      margin-bottom: 16px; }
      .OfferDetail-images--thumbnail img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
      .OfferDetail-images--thumbnail:hover .HoverOffer {
        opacity: 1; }
      @media screen and (max-width: 75em) {
        .OfferDetail-images--thumbnail {
          display: none; } }
      .OfferDetail-images--thumbnail__mobile {
        grid-area: 'thumbnail';
        display: none;
        position: relative;
        width: 100%;
        height: 672px;
        margin-bottom: 16px; }
        .OfferDetail-images--thumbnail__mobile img {
          height: 100%;
          width: 100%;
          object-fit: cover; }
        @media screen and (max-width: 75em) {
          .OfferDetail-images--thumbnail__mobile {
            display: block;
            height: 430px;
            margin-right: 16px;
            margin-bottom: 0; } }
        @media screen and (min-width: 46em) and (max-width: 48em) {
          .OfferDetail-images--thumbnail__mobile {
            display: block;
            margin-right: 16px;
            width: auto;
            margin-left: 0; } }
        @media screen and (min-width: 34.6875em) and (max-width: 45.9375em) {
          .OfferDetail-images--thumbnail__mobile {
            display: block;
            width: 548px;
            height: 320px;
            margin-left: 0;
            margin-right: 0 !important; } }
        @media screen and (max-width: 34.625em) {
          .OfferDetail-images--thumbnail__mobile {
            display: block;
            width: auto;
            height: 320px;
            margin-left: 0;
            margin-right: 0 !important; } }
    .OfferDetail-images--detail {
      grid-area: info;
      display: grid;
      grid-template-columns: repeat(3, 309px);
      grid-gap: 16px;
      margin-bottom: 160px; }
      @media screen and (min-width: 81.3125em) and (max-width: 100em) {
        .OfferDetail-images--detail {
          grid-template-columns: repeat(3, 209px); } }
      @media screen and (min-width: 75.0625em) and (max-width: 81.25em) {
        .OfferDetail-images--detail {
          grid-template-columns: repeat(3, 176px); } }
      @media screen and (min-width: 68.75em) and (max-width: 75em) {
        .OfferDetail-images--detail {
          grid-template-columns: repeat(5, 200px);
          margin-left: 16px;
          margin-right: 16px; } }
      @media screen and (min-width: 55.625em) and (max-width: 68.6875em) {
        .OfferDetail-images--detail {
          grid-template-columns: repeat(4, 200px);
          margin-left: 16px;
          margin-right: 16px;
          margin-bottom: 50px; } }
      @media screen and (min-width: 48em) and (max-width: 55.5625em) {
        .OfferDetail-images--detail {
          grid-template-columns: repeat(3, 200px);
          margin-left: 16px;
          margin-right: 16px;
          margin-bottom: 50px; } }
      @media screen and (min-width: 46em) and (max-width: 48em) {
        .OfferDetail-images--detail {
          grid-template-columns: repeat(3, 172px);
          margin-left: 16px;
          margin-right: 16px;
          margin-top: 16px;
          margin-bottom: 50px; } }
      @media screen and (min-width: 34.4375em) and (max-width: 45.9375em) {
        .OfferDetail-images--detail {
          grid-template-columns: repeat(3, 172px);
          margin-left: 16px;
          margin-right: 16px;
          margin-top: 16px;
          margin-bottom: 50px; } }
      @media screen and (min-width: 22.5em) and (max-width: 34.375em) {
        .OfferDetail-images--detail {
          grid-template-columns: repeat(2, 172px);
          margin-left: 16px;
          margin-right: 16px;
          margin-top: 16px;
          margin-bottom: 50px; } }
      @media screen and (min-width: 20.625em) and (max-width: 22.5em) {
        .OfferDetail-images--detail {
          grid-template-columns: repeat(3, 100px);
          margin-left: 16px;
          margin-right: 16px;
          margin-top: 16px;
          margin-bottom: 50px; } }
      @media screen and (max-width: 20.5625em) {
        .OfferDetail-images--detail {
          grid-template-columns: repeat(2, 100px);
          margin-left: 16px;
          margin-right: 16px;
          margin-top: 16px;
          margin-bottom: 50px; } }
      .OfferDetail-images--detail__image {
        position: relative;
        width: 100%;
        height: 200px; }
        .OfferDetail-images--detail__image img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .OfferDetail-images--detail__image:hover .HoverOffer {
          opacity: 1; }
        @media screen and (min-width: 75.0625em) and (max-width: 100em) {
          .OfferDetail-images--detail__image {
            height: 150px; } }
        @media screen and (min-width: 34.375em) and (max-width: 75em) {
          .OfferDetail-images--detail__image {
            height: 133px; } }
        @media screen and (min-width: 22.5625em) and (max-width: 34.375em) {
          .OfferDetail-images--detail__image {
            height: 110px; } }
        @media screen and (max-width: 22.5em) {
          .OfferDetail-images--detail__image {
            height: 60px; } }
  .OfferDetail .HoverOffer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(31, 31, 31, 0.8);
    opacity: 0;
    transition: opacity .3s ease; }
  .OfferDetail-info {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    background-color: #1f1f1f;
    padding: 60px;
    height: max-content; }
    @media screen and (max-width: 75em) {
      .OfferDetail-info {
        margin-right: 16px;
        margin-left: 16px;
        position: unset; } }
    @media screen and (max-width: 24.9375em) {
      .OfferDetail-info {
        padding: 30px 20px;
        position: unset; } }
  .OfferDetail-title {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 700; }
    @media screen and (max-width: 32.5em) {
      .OfferDetail-title {
        font-size: 28px; } }
  .OfferDetail-price {
    color: #da1024;
    font-weight: 700;
    font-size: 22px;
    font-family: "ProximaNova", sans-serif;
    margin-bottom: 0; }
    @media screen and (max-width: 32.5em) {
      .OfferDetail-price {
        font-size: 18px; } }
  .OfferDetail-data {
    margin-top: 60px;
    margin-bottom: 60px; }
    @media screen and (max-width: 24.9375em) {
      .OfferDetail-data {
        margin-top: 30px;
        margin-bottom: 30px; } }
    .OfferDetail-data--container {
      display: grid;
      grid-template-columns: 160px 310px;
      align-items: center;
      grid-gap: 30px;
      border-bottom: solid 1px #696969;
      padding: 10px 0; }
      .OfferDetail-data--container:last-child {
        border-bottom: none; }
      @media screen and (min-width: 27.5625em) and (max-width: 32.5em) {
        .OfferDetail-data--container {
          grid-template-columns: 160px 220px; } }
      @media screen and (min-width: 25em) and (max-width: 27.5em) {
        .OfferDetail-data--container {
          grid-template-columns: 160px 150px; } }
      @media screen and (max-width: 24.9375em) {
        .OfferDetail-data--container {
          grid-template-columns: 1fr;
          grid-gap: 10px; } }
    .OfferDetail-data-title {
      color: #ffffff;
      font-weight: 800;
      line-height: 24px;
      margin-bottom: 0; }
    .OfferDetail-data-value {
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0; }
  .OfferDetail-buttons a {
    margin-right: 30px; }
    @media screen and (max-width: 31.25em) {
      .OfferDetail-buttons a {
        margin-bottom: 20px; } }
    .OfferDetail-buttons a:last-child {
      margin-right: 0; }
      @media screen and (max-width: 31.25em) {
        .OfferDetail-buttons a:last-child {
          margin-bottom: 0px; } }

.OfferOptions {
  grid-area: content;
  margin-bottom: 140px; }
  @media screen and (max-width: 48em) {
    .OfferOptions {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 50px; } }
  .OfferOptions-title {
    color: #ffffff;
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 0; }
    @media screen and (max-width: 32.5em) {
      .OfferOptions-title {
        font-size: 28px; } }
  .OfferOptions-line {
    width: 80px;
    height: 1px;
    background-color: #da1024;
    margin-top: 40px;
    margin-bottom: 50px; }
    @media screen and (max-width: 32.5em) {
      .OfferOptions-line {
        margin-top: 30px;
        margin-bottom: 30px; } }
  .OfferOptions-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px 50px; }
    @media screen and (max-width: 48em) {
      .OfferOptions-options {
        grid-template-columns: 1fr; } }
    .OfferOptions-options p {
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0; }

.OfferForm {
  grid-area: content;
  margin-bottom: 160px; }
  @media screen and (max-width: 48em) {
    .OfferForm {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 50px; } }
  .OfferForm-title {
    max-width: 800px;
    color: #ffffff;
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 0; }
    @media screen and (max-width: 32.5em) {
      .OfferForm-title {
        font-size: 28px; } }
  .OfferForm-line {
    width: 80px;
    height: 1px;
    background-color: #da1024;
    margin-top: 40px;
    margin-bottom: 40px; }
    @media screen and (max-width: 32.5em) {
      .OfferForm-line {
        margin-top: 30px;
        margin-bottom: 30px; } }
  .OfferForm-text {
    max-width: 800px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0; }
  .OfferForm-contact {
    max-width: 800px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 45px; }
    .OfferForm-contact p {
      margin-bottom: 0; }
      .OfferForm-contact p strong {
        color: #da1024; }
      .OfferForm-contact p a {
        color: #ffffff; }
        .OfferForm-contact p a:hover {
          color: #696969; }
  .OfferForm-top {
    margin-top: 120px; }
    @media screen and (max-width: 48em) {
      .OfferForm-top {
        margin-top: 50px; } }
  .OfferForm .Form {
    max-width: 800px; }
    .OfferForm .Form-label {
      display: none; }
    .OfferForm .Form-field, .OfferForm .Form--input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      padding: 8px 0;
      background-color: transparent !important;
      border-radius: 0;
      border-top: hidden;
      border-left: hidden;
      border-right: hidden;
      border-bottom: 2px solid #696969;
      font-size: 14px;
      font-weight: 600;
      color: #da1024;
      margin-bottom: 24px; }
      .OfferForm .Form-field:active, .OfferForm .Form-field:focus, .OfferForm .Form--input:active, .OfferForm .Form--input:focus {
        border-bottom: 2px solid #da1024; }
        .OfferForm .Form-field:active::placeholder, .OfferForm .Form-field:focus::placeholder, .OfferForm .Form--input:active::placeholder, .OfferForm .Form--input:focus::placeholder {
          color: #ffffff;
          font-size: 14px;
          font-weight: 600;
          text-align: right; }
      .OfferForm .Form-field::placeholder, .OfferForm .Form--input::placeholder {
        color: #ffffff;
        font-size: 14px;
        font-weight: 600; }
    .OfferForm .Form-item--action {
      display: flex;
      justify-content: flex-end; }
