.GoogleMaps {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: -250px;
    margin-bottom: 160px;
    z-index: 1;

    @include mq($until: 1229px, $media-type: $mq-type, $mq_debug: null) {
        margin-top: 50px;
        margin-bottom: 50px;

    }

    .Maps {
        width: 1120px;
        height: 480px;

        @include mq($until: 1229px, $media-type: $mq-type, $mq_debug: null) {
            width: 100vw;
        }
    }
}