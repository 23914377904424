/* Heading */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-ProximaNova;

    .fonts-loaded & {
        font-family: $font-ProximaNova;
    }

    small {
        font-weight: normal;
    }
}

h1 {
    font-family: $font-ProximaNova;
    hyphens: auto;
    p {
        font-family: $font-ProximaNova !important;
        margin-bottom: 0;
    }
}

h2 {
    font-family: $font-ProximaNova;
}

h3 {
    @include font-size($heading-size-3, $heading-spacing-3);
}

h4 {
    @include font-size($heading-size-4, $heading-spacing-4);
}

h5 {
    @include font-size($heading-size-5, $heading-spacing-5);
}

h6 {
    @include font-size($heading-size-6, $heading-spacing-6);
}


// A series of classes for setting massive type; for use in heroes, mastheads,
// promos, etc.

.#{$namespace}Giga {
    @include font-size($heading-size-giga, $heading-spacing-giga);
}

.#{$namespace}Mega {
    @include font-size($heading-size-mega, $heading-spacing-mega);
}

.#{$namespace}Kilo {
    @include font-size($heading-size-kilo, $heading-spacing-kilo);
}

.#{$namespace}Milli {
    @include font-size($heading-size-milli, $heading-spacing-milli);
}

.#{$namespace}Micro {
    @include font-size($heading-size-micro, $heading-spacing-micro);
}
