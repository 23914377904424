/* Navigation */
.Navigation {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
        flex-direction: row-reverse;
    }

    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 160px;
        padding-right: 160px;
        width: 100%;
        margin-top: 30px;

        @include mq($from: 1025px, $until: 1620px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 50px;
            padding-right: 50px;
        } 

        @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 20px;
            padding-right: 20px;
            align-items: center;
            justify-content: center;
        } 
    }

    &-button {
        display: none;
    }

    #menu-button {
        position: relative;
        z-index: 5;
        cursor: pointer;
        user-select: none;
        height: 27px;
        // right: 30px;
        // top: calc(50% - 18px);

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;

        @include mq($from: 1025px, $media-type: $mq-type, $mq_debug: null) {
            display: none !important;
        } 

        span {
            display: block;
            position: relative;
            height: 2px;
            width: 30px;
            border-radius: 2px;
            opacity: 1;
            background-color: $cc-red;

            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            -o-transition: .3s ease-in-out;
            transition: .3s ease-in-out;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 7px;
            }

            &:nth-child(3) {
                top: 14px;
            }
        }
    }


    &-button:checked ~ #menu-button {
        
        span {
            background-color: $white;
            
            &:nth-child(1) {
                top: 11px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                top: 8px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }
        }
    }

    &-button:checked ~ .Navigation-body {
        transform: translateX(0);
        transition: all .3s ease-in-out;
        z-index: -1;
    }

    &-button:checked ~ .Logo2 {
        svg {
            path {
                transition: .5s fill ease;
                fill: $white;
            }
        } 
    }

    &-body {
        visibility: visible;
        z-index: 2;
        display: flex;

        @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
            position: fixed;
            top: 0;
            right: 0;
            min-height: 100vh;
            height: -webkit-fill-available;
            width: 100vw;
            background-color: $cc-darkGrey;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            transform: translateX(100vw);
            transition: all .3s ease-in-out;
            padding-top: 70px;
            padding-bottom: 50px;
            flex-direction: column;
        } 
    }

    &-socials {
        display: flex;
        align-items: center;
        margin-left: 70px;

        a {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
        
        svg {
            height: 24px;
            width: auto;

            path {
                transition: fill .3s ease;

                &:hover {
                    fill: $cc-red;
                }
            }
        }

        @include mq($from: 1025px, $until: 1200px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 30px;
        } 

        @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 0;
            justify-content: center;
        } 
    }
}

/* Types */
@import '6-components/navigation/main';
@import '6-components/navigation/lang';
@import '6-components/navigation/footer';
